import React from 'react';
import { useSelector } from 'react-redux';

import { Seller } from 'types';

import { nt } from './utils';
import OnboardingSidebar from './OnboardingSidebar';

import { userSelector } from 'shared/selectors';
import * as Consent from 'shared/components/Consent';
import ContainerWithoutPadding from 'shared/components/tailwind/ContainerWithoutPadding';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import ProposalSummary from './ProductInformation/ProposalSummary';
import ProposalLink from './ProductInformation/ProposalLink';
import WithQuestions from './Submitted/WithQuestions';
import WithoutQuestions from './Submitted/WithoutQuestions';
import SubmittedActions from './Submitted/SubmittedActions';

const ns = 'signup.onboarding.without_contract';
const t = nt(ns);

interface Props {
  basePath: string;
  seller: Seller;
}

const Submitted = ({ basePath, seller }: Props) => {
  const openQuestions =
    seller.questions.inquiry.status === 'unanswered_questions' ||
    seller.questions.requirement.status === 'unanswered_questions';
  const user = useSelector(userSelector);

  return (
    <>
      <ContainerWithoutPadding>
        <div className="tw-mb-6 tw-bg-[#e0f0da] tw-px-4 tw-py-4 sm:tw-px-8 md:tw--mx-8 md:tw-mb-10 md:tw-mt-7 md:tw-rounded-lg md:tw-py-10">
          <div className="tw-mb-2 tw-font-heading tw-text-lg tw-font-bold md:tw-text-bootstrap-h3">
            {t('welcome_banner.title', { firstName: user?.firstName })}
          </div>
          <div className="tw-text-lg md:tw-text-2xl">
            {t('welcome_banner.message')}
          </div>
        </div>
      </ContainerWithoutPadding>
      <ContentWithSidebar
        aboveMainChildren={
          <>
            <Consent.Feedback t={nt(`${ns}.consentFeedback`)} />
            <h3 className="tw-mb-5 tw-text-lg md:tw-text-bootstrap-h3">
              {t('title')}
            </h3>
          </>
        }
        includeTopMargin={false}
        sidebarChildren={<OnboardingSidebar />}
        sidebarClassName=""
      >
        <div className="tw-flex tw-flex-col tw-gap-y-4 md:tw-mr-16">
          {(seller.showProposalSummary || seller.showAutomatedOffer) && (
            <>
              <ProposalSummary />
              <ProposalLink basePath={basePath} seller={seller} />
            </>
          )}
          {openQuestions ? (
            <WithQuestions basePath={basePath} seller={seller} />
          ) : (
            <WithoutQuestions basePath={basePath} seller={seller} />
          )}
          <SubmittedActions basePath={basePath} seller={seller} />
        </div>
      </ContentWithSidebar>
    </>
  );
};

export default Submitted;
