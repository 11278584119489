import React from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import Chart from 'charlie/components/AccountSummary/Chart';
import AnimatedNumber from 'shared/components/AnimatedNumber';
import { numberFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { calculatePercentage } from 'charlie/components/AccountSummary/utils';
import ChevronDown from 'svg/chevron_down.svg';
import ChevronUp from 'svg/chevron_up.svg';
import { useSelectDashboardFromRedux } from './useDashboard';
import MobileSummaryDetails from './MobileSummaryDetails';

const ns = 'charlie.frontpage.account_summary';

const t = (key: string) => I18n.nt(ns, key);

const MobileSummary = () => {
  const dashboard = useSelectDashboardFromRedux();

  if (!dashboard) {
    return null;
  }

  const availableBalance = parseFloat(dashboard.availableBalance);
  const creditLimit = parseFloat(dashboard.creditLimit);
  const chartPercentage = calculatePercentage(availableBalance, creditLimit);

  return (
    <div className="tw-bg-floryn-tint-gray tw-pt-4">
      <div className="FlorynContainer">
        <div className="tw-font-heading">
          <div className="tw-text-lg tw-font-semibold">
            {t('available_balance')}
          </div>
          <span className="tw-text-4xl tw-font-bold">
            <span className="tw-mr-2">€</span>
            <AnimatedNumber
              duration={800}
              formatValue={numberFormat}
              value={availableBalance}
            />
          </span>
        </div>
        <Chart chartPercentage={chartPercentage} />
        <Disclosure>
          {({ open }) => (
            <>
              <DisclosureButton
                className={classNames(
                  'tw-font-heading tw-font-semibold',
                  !open && 'tw-mb-4'
                )}
              >
                <div className="tw-flex tw-items-center tw-gap-2">
                  {t('more_information_about_your_credit')}
                  {open ? (
                    <ChevronUp className="tw-h-4 tw-w-4 tw-fill-gray-500" />
                  ) : (
                    <ChevronDown className="tw-h-4 tw-w-4 tw-fill-gray-500" />
                  )}
                </div>
              </DisclosureButton>
              <Transition
                show={open}
                enter="tw-transition tw-duration-100 tw-ease-out"
                enterFrom="tw-transform tw-scale-95 tw-opacity-0"
                enterTo="tw-transform tw-scale-100 tw-opacity-100"
                leave="tw-transition tw-duration-100 tw-ease-out"
                leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
                leaveTo="tw-transform tw-scale-95 tw-opacity-0"
              >
                <DisclosurePanel static>
                  <MobileSummaryDetails />
                </DisclosurePanel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default MobileSummary;
