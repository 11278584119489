import React from 'react';
import { useSelector } from 'react-redux';
import { useCurrentSeller } from 'shared/hooks';
import { isCancelled } from 'models/Seller';
import { ReduxState } from 'types';
import { AlertWithLink } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';

const ns = 'charlie.frontpage.warnings';

const DeliveryWarning = () => {
  const currentSeller = useCurrentSeller();
  const hasActionableDeliveries = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.hasCustomerActionableDeliveries ?? false
  );

  if (isCancelled(currentSeller) || !hasActionableDeliveries) {
    return null;
  }

  return (
    <AlertWithLink
      actionText={I18n.nt(ns, 'delivery_action')}
      actionUrl={routes.PROFILE_PAGE_DELIVERIES}
      type="warning"
    >
      {I18n.nt(ns, 'delivery_message')}
    </AlertWithLink>
  );
};

export default DeliveryWarning;
