import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import resolver from 'hookForm/resolver';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { toEuro } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { validateAll, validatePresence } from 'shared/utils/validation';
import { MinimalSeller } from 'types';
import { trackFormSubmit } from 'shared/utils/tracker';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import client from 'shared/utils/client';
import { updateReviewInfo } from 'charlie/modules/dashboardSlice';
import useAppDispatch from 'shared/hooks/useAppDispatch';

const ns = 'charlie.frontpage.increase_limit_modal';

interface FormValues {
  amount: number;
  comment: string;
}

type Status = '' | 'open_review' | 'success';

const MAX_AMOUNT = 10_000_000;

const validateCreditLimit = (creditLimit: number) => (value) =>
  value > creditLimit
    ? null
    : I18n.nt(ns, 'must_be_greater_than_current_amount');

const validateMaxAmount = () => (value) =>
  value < MAX_AMOUNT
    ? null
    : I18n.nt(ns, 'maximum_amount_error', { maximum: toEuro(MAX_AMOUNT) });

const validateAmount = (value: number | null, creditLimit: number) =>
  validateAll(
    validatePresence,
    validateCreditLimit(creditLimit),
    validateMaxAmount()
  )(value);

const useIncreaseLimit = (parentPath: string) => {
  const appDispatch = useAppDispatch();
  const history = useHistory();

  const [status, setStatus] = useState<Status>('');
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const close = () => history.push(parentPath);

  const seller = useCurrentSeller() as MinimalSeller;
  const creditLimit = parseFloat(seller.creditLimit || '0');

  const validate = ({ amount, comment }: FormValues) => ({
    comment: validatePresence(comment),
    amount: validateAmount(amount, creditLimit),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = async (values: FormValues) => {
    const sellerId = seller.id;
    const response = await client(
      'POST',
      '/api/app/increase_limit',
      {
        ...values,
        sellerId,
      },
      { raiseError: false }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      trackFormSubmit('increase-limit');

      if (response.payload.requiresReview) {
        if (response.payload.review) {
          appDispatch(updateReviewInfo(response.payload.review));
        }

        setStatus('open_review');
      } else {
        setStatus('success');
      }
    }
  };

  return {
    close,
    control,
    creditLimit,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    seller,
    serverErrorMessages,
    status,
  };
};

export default useIncreaseLimit;
