import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

const useLiable = () => {
  const dashboardState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload
  );

  if (!dashboardState) {
    return {
      debtCollectionAmount: 0,
      liableAmount: 0,
      reversalCount: 0,
    };
  }

  const getDebtCollectionAmount = () => {
    if (dashboardState?.nextDebtCollection) {
      return parseFloat(dashboardState.nextDebtCollection.amount);
    }

    if (dashboardState?.currentCommitment) {
      return parseFloat(dashboardState.currentCommitment.amount);
    }

    return 0;
  };

  const liableAmount = parseFloat(dashboardState?.liable ?? '0');
  const debtCollectionAmount = getDebtCollectionAmount();
  const reversalCount =
    debtCollectionAmount === 0 ? 0 : liableAmount / debtCollectionAmount;

  return { debtCollectionAmount, liableAmount, reversalCount };
};

export default useLiable;
