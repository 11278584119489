const methods = ['log', 'warn', 'error'];

const stub = {};
for (const method of methods) {
  stub[method] = () => {};
}

const konsole =
  process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' // eslint-disable-line no-undef
    ? console
    : stub;

export default konsole;
