import React from 'react';
import { Seller } from 'types';
import { isAlfa, isDelta } from 'models/Seller';
import { Link } from 'react-router-dom';
import { nt } from 'Onboarding/components/utils';
import { scrollToTop } from 'namespaces/shared/utils/scroll';

const t = nt('signup.onboarding.proposal_summary');

interface Props {
  basePath: string;
  seller: Seller;
}

const showProposalLink = (seller: Seller) => {
  if (isAlfa(seller) || isDelta(seller)) {
    return false;
  }

  return true;
};

const ProposalLink = ({ basePath, seller }: Props) => {
  if (!showProposalLink(seller)) {
    return null;
  }

  return (
    <Link
      className="tw-mb-4 tw-block tw-font-bold tw-underline"
      to={`${basePath}/product-informatie`}
      onClick={() => scrollToTop()}
    >
      {t('proposal_link')}
    </Link>
  );
};

export default ProposalLink;
