import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import { parseISO } from 'date-fns';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { cleanIban } from 'models/Iban';
import { MinimalSeller } from 'types/Seller';
import { ReduxState } from 'types/ReduxState';
import { calculateNewDebtCollectionAmount } from 'charlie/model';
import client from 'shared/utils/client';
import useValidatePayForm from './useValidatePayForm';
import { FormValues, FlorynPayPaymentResponse } from './types';
import { trackFormSubmit } from 'shared/utils/tracker';

const withdrawalFee = (amount: number, withdrawalFeeRate: number) =>
  amount * withdrawalFeeRate;

const usePayForm = (
  gotoProcessingStep: (florynPayPaymentId: number) => void
) => {
  const seller = useCurrentSeller() as MinimalSeller;
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const validate = useValidatePayForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    watch,
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = async (paymentDetails: FormValues) => {
    const cleanedPaymentReference = paymentDetails.paymentReference
      ? paymentDetails.paymentReference.replace(/_/g, '')
      : undefined;

    const response = await client<FlorynPayPaymentResponse>(
      'POST',
      '/api/floryn_pay_payments',
      {
        amount: paymentDetails.amount,
        description: paymentDetails.description,
        creditorIban: cleanIban(paymentDetails.creditorIban),
        creditorName: paymentDetails.creditorName,
        paymentReference: cleanedPaymentReference,
        sellerId: seller.id,
      },
      { raiseError: false }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      trackFormSubmit('floryn-pay-add-payment');
      const florynPayPaymentId = response.payload.florynPayPayment.id;

      gotoProcessingStep(florynPayPaymentId);
    }
  };

  const amount = watch('amount') || 0.0;
  const creditorIban: string = watch('creditorIban') || '';
  const creditorName: string = watch('creditorName') || '';

  const availableBalanceFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.availableBalance
  );
  const availableBalance = parseFloat(availableBalanceFromState || '');

  const debtCollectionForNewWithdrawal = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionForNewWithdrawal
  );

  const debtCollectionRateFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionRate || ''
  );
  const debtCollectionRate = parseFloat(debtCollectionRateFromState || '');

  const pendingCurrentLimitFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.pendingCurrentLimit
  );
  const pendingCurrentLimit = parseFloat(pendingCurrentLimitFromState || '');

  const requestedAmountFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.requestedAmount
  );
  const requestedAmount = parseFloat(requestedAmountFromState || '');

  const withdrawalFeeRateFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.withdrawalFeeRate
  );
  const withdrawalFeeRate = parseFloat(withdrawalFeeRateFromState || '0');

  const amountIncludingWithdrawalFee =
    amount + withdrawalFee(amount, withdrawalFeeRate);

  const currentDebtCollectionAmount = debtCollectionForNewWithdrawal
    ? parseFloat(debtCollectionForNewWithdrawal?.amount)
    : 0;
  const debtCollectionDate = debtCollectionForNewWithdrawal
    ? parseISO(debtCollectionForNewWithdrawal.date)
    : undefined;
  const newDebtCollectionAmount = calculateNewDebtCollectionAmount({
    currentDebtCollectionAmount,
    debtCollectionRate: debtCollectionRate,
    pendingCurrentLimit: pendingCurrentLimit,
    requestedAmount: requestedAmount,
    withdrawAmount: amountIncludingWithdrawalFee,
  });

  const pendingCurrentBalanceFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.pendingCurrentBalance
  );
  const pendingCurrentBalance = parseFloat(
    pendingCurrentBalanceFromState || ''
  );

  const summaryProperties = {
    amount,
    debtCollectionDate,
    creditorIban,
    creditorName,
    newCurrentBalance: pendingCurrentBalance - amountIncludingWithdrawalFee,
    newDebtCollectionAmount,
    withdrawalFee: withdrawalFee(amount, withdrawalFeeRate),
    withdrawalFeeRate,
  };

  return {
    availableBalance,
    control,
    generalErrors: serverErrorMessages,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    summaryProperties,
    watch,
  };
};

export default usePayForm;
