import React from 'react';
import { Col, Row } from 'shared/bootstrap';
import CoSignerFields from './CoSignerFields';
import MaskedTextInput from 'hookForm/MaskedTextInput';
import RadioButtons from 'hookForm/RadioButtons';
import RadioInput from 'hookForm/RadioInput';
import TextInput from 'hookForm/TextInput';
import { nt } from 'Onboarding/components/utils';
import { Alert } from 'shared/components/tailwind/Alert';
import Button from 'shared/components/tailwind/Button';
import useGuaranteeForm from 'shared/components/GuaranteeForm/useGuaranteeForm';
import * as I18n from 'shared/utils/I18n';
import Guarantee from 'types/Guarantee';
import LoadingAnimation from '../LoadingAnimation';

export interface FormValues {
  city: string;
  confirmNoPartner: boolean;
  coSignerEmail: string;
  coSignerName: string;
  dateOfBirth: string;
  fullName: string;
  guaranteeRequiresCoSigner: 'true' | 'false';
  houseNumber: string;
  placeOfBirth: string;
  postalCode: string;
  street: string;
}

interface ComponentProps {
  guarantee: Guarantee;
  onSubmit: (values: FormValues) => Promise<void>;
  sellerEmail: string;
}

const t = nt('shared.guarantee_form');

const GuaranteeForm = ({
  guarantee,
  onSubmit,
  sellerEmail,
}: ComponentProps) => {
  const { control, error, handleSubmit, isSubmitting, watch } =
    useGuaranteeForm({
      guarantee,
      onSubmit,
      sellerEmail,
    });

  const guaranteeRequiresCoSignerValue = watch('guaranteeRequiresCoSigner');

  return (
    <form onSubmit={handleSubmit}>
      {error && <Alert type="danger">{error}</Alert>}
      <p>{t('form_description')}</p>
      <Row>
        <Col md={12}>
          <TextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'fullName')}
            name="fullName"
            readOnly={true}
            required={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <MaskedTextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'dateOfBirth')}
            mask="##-##-####"
            name="dateOfBirth"
            placeholder="DD-MM-JJJJ"
          />
        </Col>
        <Col md={6}>
          <TextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'placeOfBirth')}
            name="placeOfBirth"
            placeholder="Utrecht"
          />
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <TextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'street')}
            name="street"
            placeholder="Walpoort"
          />
        </Col>
        <Col md={3}>
          <TextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'houseNumber')}
            name="houseNumber"
            placeholder="10"
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <TextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'postalCode')}
            name="postalCode"
            placeholder="5211 DK"
          />
        </Col>
        <Col md={9}>
          <TextInput
            control={control}
            label={I18n.label('contracting/guarantee', 'city')}
            name="city"
            placeholder="'s-Hertogenbosch"
          />
        </Col>
      </Row>
      <p>{t('co_signer_description')}</p>
      <RadioButtons hideLabel={true} label="" name="guaranteeRequiresCoSigner">
        <RadioInput
          control={control}
          label={t('requires_co_signer.true')}
          name="guaranteeRequiresCoSigner"
          value="true"
        />
        <RadioInput
          control={control}
          label={t('requires_co_signer.false')}
          name="guaranteeRequiresCoSigner"
          value="false"
        />
      </RadioButtons>
      <CoSignerFields
        control={control}
        guaranteeRequiresCoSigner={guaranteeRequiresCoSignerValue}
        t={t}
      />
      <div className="inner-modal-footer">
        {isSubmitting && <LoadingAnimation />}
        <Button type="submit" disabled={isSubmitting} className="btn-primary">
          {t('submit_button')}
        </Button>
        <div className="tw-mt-2 tw-text-gray-500">
          {t('submit_button_info')}
        </div>
      </div>
    </form>
  );
};

export default GuaranteeForm;
