import React from 'react';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.extra_pay_off_modal';

interface Props {
  currentBalance: string;
  hasDebtCollection: boolean;
}

const CreditProductDescription = ({
  currentBalance,
  hasDebtCollection,
}: Props) => (
  <div className="tw-flex tw-flex-col tw-gap-5">
    <div>{I18n.nt(ns, 'summary_with_credit_product1')}</div>
    <div>
      {I18n.nt(ns, 'summary_with_credit_product2_html', {
        amount: currentBalance,
      })}
    </div>
    {hasDebtCollection && (
      <div>
        {I18n.nt(ns, 'summary_with_credit_product_and_debt_collection')}
      </div>
    )}
  </div>
);

export default CreditProductDescription;
