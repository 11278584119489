import React from 'react';

interface Props {
  chartPercentage: number;
}

const Chart = ({ chartPercentage }: Props) => (
  <div className="tw-mb-4 tw-mt-2 tw-h-[20px] tw-w-full tw-rounded tw-bg-gray-400">
    <div
      className="tw-h-full tw-origin-top-left tw-rounded tw-bg-floryn-green tw-transition-transform tw-duration-500 tw-ease-out"
      style={{
        transform: `scaleX(${chartPercentage})`,
      }}
    />
  </div>
);

export default Chart;
