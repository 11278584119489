import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { toEuro } from 'shared/utils/Helpers';
import useDeltaExtraPayOffModal from './useDeltaExtraPayOffModal';
import { DescriptionList, Description } from './DescriptionList';

const ns = 'delta.frontpage.extra_pay_off_modal';

interface Props {
  parentPath: string;
}

const DeltaExtraPayOffModal = ({ parentPath }: Props) => {
  const { florynIban, transferReference, remainingRepaymentAmount, onHide } =
    useDeltaExtraPayOffModal(parentPath);

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="tw-mb-8">{I18n.nt(ns, 'intro')}</p>
        <div className="well tw-mb-8">
          <DescriptionList>
            <Description
              term={I18n.nt(ns, 'iban')}
              details={formatIban(florynIban) ?? ''}
            />
            <Description
              term={I18n.nt(ns, 'to_the_attention_of')}
              details={I18n.nt(ns, 'foundation_floryn_payments')}
            />
            <Description
              term={I18n.nt(ns, 'description')}
              details={I18n.nt(ns, 'reference', {
                reference: transferReference,
              })}
            />
          </DescriptionList>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-8">
          <div>{I18n.nt(ns, 'summary1')}</div>
          <div>
            {I18n.nt(ns, 'summary2_html', {
              amount: toEuro(remainingRepaymentAmount),
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DeltaExtraPayOffModal;
