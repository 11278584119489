import React from 'react';
import { parseISO } from 'date-fns';
import { truncate } from 'lodash';

import * as Helpers from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import { translate } from 'shared/utils/I18n';
import { FlorynInvoice } from 'types';
import useFlorynInvoices from './useFlorynInvoices';

const t = translate('app.profilePage.floryn_invoices');

interface FlorynInvoiceProps {
  invoice: FlorynInvoice;
  key: number;
}

const FlorynInvoiceRow = ({ invoice }: FlorynInvoiceProps) => {
  const {
    companyName,
    invoiceDate,
    periodDescription,
    reference,
    sellerId,
    totalAmountWithTaxes,
  } = invoice;

  const hasTotalAmount = parseFloat(totalAmountWithTaxes) > 0.0;
  const totalAmount = Helpers.toEuro(totalAmountWithTaxes);

  return (
    <tr key={reference}>
      <td>
        <div className="MobileOnly header">Factuurnummer</div>
        {reference}
      </td>
      <td>
        <div className="MobileOnly header">Factuurdatum</div>
        {Helpers.explicitDateFormat(parseISO(invoiceDate), 'dd-MM-yyyy')}
      </td>
      <td>
        <div className="MobileOnly header">Periode</div>
        {periodDescription}
      </td>
      <td>
        {hasTotalAmount && (
          <>
            <div className="MobileOnly header">Bedrag</div>
            {totalAmount}
          </>
        )}
      </td>
      <td>
        <div className="MobileOnly header">Bedrijfsnaam</div>
        {truncate(companyName, { length: 35 })}
      </td>
      <td>
        <a
          target="_blank"
          href={`/api/sellers/${sellerId}/floryn-invoices/${invoice.id}`}
        >
          <Button type="button" className="btn-sm btn-primary-inverted">
            Download
          </Button>
        </a>
      </td>
    </tr>
  );
};

const FlorynInvoices = () => {
  const { florynInvoices, status } = useFlorynInvoices();

  if (status !== 'loaded') {
    return <LoadingAnimation />;
  }

  if (florynInvoices.length === 0) {
    return <strong>{I18n.nt('app.profilePage', 'noFlorynInvoices')}</strong>;
  }

  return (
    <>
      <h3>{t('title')}</h3>
      <table className="FlorynInvoices">
        <thead className="DesktopOnly">
          <tr>
            <th>{I18n.attribute('floryn_invoice', 'reference')}</th>
            <th>{I18n.attribute('floryn_invoice', 'begin_of_period')}</th>
            <th>{I18n.attribute('floryn_invoice', 'period')}</th>
            <th>
              {I18n.attribute('floryn_invoice', 'total_amount_with_taxes')}
            </th>
            <th>{I18n.attribute('company', 'name')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {florynInvoices.map((invoice: FlorynInvoice) => (
            <FlorynInvoiceRow key={invoice.id} invoice={invoice} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default FlorynInvoices;
