import { useHistory } from 'react-router-dom';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { MinimalSeller, ReduxState } from 'types';
import { useSelector } from 'react-redux';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import { getProduct } from 'models/Seller';
import { nt } from 'shared/utils/I18n';
import client from 'shared/utils/client';
import { useState } from 'react';
const ns = 'app.products';

interface InitiatePaymentPayload {
  redirectUrl?: string;
}

const repaymentSelector = (state: ReduxState): string | undefined =>
  state.charlie?.dashboard?.payload?.florynIban;

const useRepayment = (parentPath: string) => {
  const florynIban = useSelector(repaymentSelector);
  const history = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<
    'loading' | 'loaded' | 'error'
  >();

  const seller = useCurrentSeller() as MinimalSeller;
  const productName = nt(ns, getProduct(seller));

  const initiatePayment = async () => {
    setLoadingStatus('loading');
    const response = await client<InitiatePaymentPayload>(
      'POST',
      `/api/ideal/initiate_payment`,
      { sellerId: seller.id },
      { raiseError: false }
    );

    if (response.error) {
      setLoadingStatus('error');
    } else {
      const url = response.payload?.redirectUrl;
      if (url) {
        window.open(url, '_self');
      } else {
        setLoadingStatus('error');
      }
    }
  };

  let transferReference = seller.id.toString();
  transferReference += useIsComplexAccount() ? ` ${productName}` : '';

  const close = () => history.push(parentPath);

  return {
    florynIban,
    transferReference,
    close,
    loadingStatus,
    initiatePayment,
    seller,
  };
};

export default useRepayment;
