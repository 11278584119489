import React from 'react';
import {
  Link,
  Redirect,
  Route,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { usesClientBankAccount } from 'charlie/model';
import * as I18n from 'shared/utils/I18n';
import { useCurrentSeller } from 'shared/hooks';
import Button from 'shared/components/tailwind/Button';
import SearchForm from '../SearchForm';
import ExportModal from 'charlie/components/ExportModal';
import { isCancelled } from 'models/Seller';

const ns = 'charlie.frontpage.actions';

const AlfaActions = () => {
  const seller = useCurrentSeller();
  const match = useRouteMatch();
  const history = useHistory();
  const canUploadInvoice = !isCancelled(seller);

  return (
    <div className="c-actions">
      <div className="c-actions-links">
        {canUploadInvoice && (
          <Button
            className="btn btn-primary"
            onClick={() => history.push(`${match.path}/factuur/new`)}
          >
            {I18n.nt(ns, 'upload_invoice')}
          </Button>
        )}
        {usesClientBankAccount(seller) && (
          <Link
            to={`${match.path}/transacties-downloaden`}
            className="btn btn-tertiary-inverted"
          >
            {I18n.nt(ns, 'export_transactions')}
          </Link>
        )}
        <Route
          path={`${match.path}/transacties-downloaden`}
          render={() =>
            usesClientBankAccount(seller) ? (
              <ExportModal parentPath={match.path} />
            ) : (
              <Redirect to={match.path} />
            )
          }
        />
      </div>
      <SearchForm sellerId={seller.id} />
    </div>
  );
};

export default AlfaActions;
