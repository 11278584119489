import React from 'react';
import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';

const ns = 'charlie.frontpage.extra_pay_off_modal';

interface Props {
  currentBalance: string;
  maxRemainingExtraRepaymentAmount?: string;
}

const LoanDescription = ({
  currentBalance,
  maxRemainingExtraRepaymentAmount,
}: Props) => (
  <div className="tw-flex tw-flex-col tw-gap-8">
    <div>{I18n.nt(ns, 'summary_with_loan_product1')}</div>
    <div>{I18n.nt(ns, 'summary_with_loan_product2_html')}</div>
    <div>
      {I18n.nt(ns, 'summary_with_loan_product3_html', {
        amount: currentBalance,
        max: toEuro(maxRemainingExtraRepaymentAmount),
      })}
    </div>
  </div>
);

export default LoanDescription;
