import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import { trackDocumentsComplete } from 'shared/utils/tracker';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import { load as loadReview } from 'shared/modules/review';
import {
  goToPreviousStep,
  setBankStatementUploadType,
  submitSeller,
  updateBankStatementAttachments,
} from 'signup/actions/SellerSignup';
import { ReduxState, Seller } from 'types';
import { BankStatementAttachment } from 'types/Attachment';
import { validatePresence } from 'shared/utils/validation';

type Status = 'loading' | 'ready' | 'saving';

interface DocumentUploadFormData {
  bankStatementAttachments: BankStatementAttachment[];
}

const isValidAttachment = (attachment: BankStatementAttachment) =>
  attachment?.diagnostics?.type !== 'warning';

const validate = ({ bankStatementAttachments }: DocumentUploadFormData) => ({
  bankStatementAttachments: validatePresence(bankStatementAttachments),
});

const useDocumentUpload = () => {
  const dispatch = useDispatch();
  const attachmentsMap = useSelector(
    (state: ReduxState) => state.entities.attachments
  );
  const review = useSelector(
    (state: ReduxState) => state.charlie.review.payload
  );
  const seller = useCurrentSeller() as Seller;

  const [uploading, setUploading] = useState(false);
  const [updatingAttachments, setUpdatingAttachments] = useState(false);

  const defaultAttachments = attachmentsMap
    ? seller.bankStatementAttachments.map(
        (attachmentId) => attachmentsMap[attachmentId]
      )
    : [];

  const {
    control,
    formState: { isSubmitting, touchedFields },
    handleSubmit,
    setValue,
  } = useForm<DocumentUploadFormData>({
    defaultValues: {
      bankStatementAttachments: defaultAttachments,
    },
    resolver: resolver(validate),
  });
  const bankStatementAttachments = useWatch({
    control,
    name: 'bankStatementAttachments',
  });

  const updateAttachments = async () => {
    setUpdatingAttachments(true);
    await dispatch(
      updateBankStatementAttachments(
        bankStatementAttachments.map((attachment) => attachment.id),
        'signupBankStatementUpload'
      )
    );
    setUpdatingAttachments(false);
    dispatch(loadReview(seller.id));
  };

  useEffect(() => {
    dispatch(loadReview(seller.id));
  }, []);

  useEffect(() => {
    if (touchedFields.bankStatementAttachments) {
      updateAttachments();
    }
  }, [bankStatementAttachments]);

  const attachmentsWithWarning = bankStatementAttachments.filter(
    (attachment) => !isValidAttachment(attachment as BankStatementAttachment)
  );

  const handleBankConnectionLink = () => {
    dispatch(setBankStatementUploadType('psd2'));
  };

  const removeInvalidAttachments = () => {
    setValue(
      'bankStatementAttachments',
      bankStatementAttachments.filter((attachment) =>
        isValidAttachment(attachment as BankStatementAttachment)
      ),
      { shouldTouch: true, shouldDirty: true }
    );
  };

  const submit = () => {
    trackDocumentsComplete('documents', seller.email, '');
    dispatch(submitSeller());
  };

  const getStatus = (): Status => {
    if (!review) {
      return 'loading';
    }

    if (uploading || isSubmitting || updatingAttachments) {
      return 'saving';
    }

    return 'ready';
  };

  const warnings =
    attachmentsWithWarning.length !== 0 ||
    (review?.missingPeriods && review.missingPeriods.length > 0) ||
    false;

  return {
    attachmentsWithWarning,
    bankStatementAttachments,
    control,
    goToPreviousStep: () => dispatch(goToPreviousStep()),
    handleBankConnectionLink,
    removeInvalidAttachments,
    seller,
    setUploading,
    status: getStatus(),
    submit: handleSubmit(submit),
    warnings,
  };
};

export default useDocumentUpload;
