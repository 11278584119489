import { chain, isPlainObject, isNil } from 'lodash';
import 'whatwg-fetch';
import cookie from 'js-cookie';

import konsole from './konsole';
import { underscore } from 'inflected';
import { underscoreKeys, camelizeKeys } from 'shared/utils/humpsInflection';

type SuccessResult<Payload = any> = {
  currentBundleVersion: string | null;
  error: false;
  payload: Payload;
  statusCode: number;
  statusText: string;
};

type ErrorResult = {
  currentBundleVersion: string | null;
  error: true;
  payload: Record<string, unknown>;
  statusCode: number;
  statusText: string;
};

export type Result<Payload = any> = SuccessResult<Payload> | ErrorResult;

interface Options extends RequestInit {
  acceptHtml?: boolean;
  body?: any;
  raiseError?: boolean;
}

const gitRevisionHash = process.env.GIT_REVISION_HASH
  ? process.env.GIT_REVISION_HASH
  : window.APPLICATION_GLOBALS?.GIT_REVISION_HASH;

const toQueryString = (obj: any): string =>
  chain(obj)
    .omitBy((v) => v === null || v === undefined)
    .map(
      (v, k) => encodeURIComponent(underscore(k)) + '=' + encodeURIComponent(v)
    )
    .join('&')
    .thru((s) => (s === '' ? '' : '?' + s))
    .value();

const headersToArray = (headers: Headers) => {
  const result: any[] = [];

  for (const header of headers) {
    result.push(header);
  }

  return result;
};

const parseResponse = async (
  options: Options,
  response: Response
): Promise<null | string | Record<string, unknown>> => {
  if (response.status === 204) {
    return null;
  } else if (options.acceptHtml || response.status === 500) {
    return response.text();
  } else {
    const body = await response.text();

    try {
      return camelizeKeys(JSON.parse(body));
    } catch (error) {
      if (response.status >= 400) {
        // An error response can return json or text
        return body;
      } else {
        throw error;
      }
    }
  }
};

async function transform<Payload>(
  options: Options,
  response: Response
): Promise<Result<Payload>> {
  const payload = (await parseResponse(options, response)) as Payload;

  try {
    return {
      currentBundleVersion: response.headers.get('x-current-bundle-version'),
      error: false,
      payload,
      statusCode: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    const { ok, redirected, status, statusText, type, url } = response;

    throw {
      currentBundleVersion: response.headers.get('x-current-bundle-version'),
      error,
      request: {
        ...options,
        headers: options.headers
          ? headersToArray(options.headers as Headers)
          : [],
      },
      response: {
        headers: headersToArray(response.headers),
        ok,
        redirected,
        status,
        statusText,
        type,
        url,
      },
      statusCode: -1,
    };
  }
}

function handleErrors<Payload>(
  clientResponse: Result<Payload>,
  options: Options
): Result<Payload> {
  if (clientResponse.statusCode === 500) {
    konsole.error(`Got an error from the API: ${clientResponse.statusText}`);
  }

  if (clientResponse.statusCode < 200 || clientResponse.statusCode >= 300) {
    clientResponse.error = true;

    if (options.raiseError) {
      throw clientResponse;
    }
  }

  return clientResponse;
}

async function client<Payload = any>(
  method: string,
  path: string,
  data: any = {},
  options: Options = {}
): Promise<Result<Payload>> {
  method = method.toUpperCase();

  if (!options.body && method !== 'GET' && method !== 'HEAD') {
    if (isPlainObject(data)) {
      options.body = JSON.stringify(underscoreKeys(data));
    } else {
      options.body = data;
    }
  }

  if (options.raiseError === undefined) {
    options.raiseError = true;
  }

  if (method === 'GET') {
    path = path + toQueryString(data);
  }

  options.headers = new Headers(options.headers ? options.headers : {});

  if (isPlainObject(data)) {
    options.headers.set('Content-Type', 'application/json');
  }

  const cookieCSRFToken = cookie.get('CSRF-TOKEN');

  if (cookieCSRFToken) {
    options.headers.set('X-CSRF-Token', cookieCSRFToken);
  }

  if (gitRevisionHash) {
    options.headers.set('Client-Version', gitRevisionHash);
  }

  const info: RequestInit = {
    method,
    credentials: 'include',
    ...options,
  };

  try {
    const response = await fetch(path, info);
    const result = await transform<Payload>(info, response);
    return handleErrors<Payload>(result, options);
  } catch (error) {
    // thrown by handleErrors
    if (error.error && !isNil(error.payload)) {
      throw error;
    }
    // unknown error (possibly thrown by browser)
    else {
      const response: Result<Payload> = {
        currentBundleVersion: 'unknown',
        error: true,
        payload: {},
        statusCode: 0,
        statusText: 'no response received',
      };
      if (options.raiseError) {
        throw response;
      } else {
        return response;
      }
    }
  }
}

export default client;
