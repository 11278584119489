import React from 'react';

import LiableWarning from './LiableWarning';
import DeliveryWarning from './DeliveryWarning';
import ReviewWarning from './ReviewWarning';
import RejectedWithdrawalWarning from './RejectedWithdrawalWarning';
import SettlementWarning from './SettlementWarning';
import CancelledAlert from './CancelledAlert';
import { isAlfa } from 'models/Seller';
import { useCurrentSeller } from 'shared/hooks';
import DashboardAnnouncements from 'shared/components/Announcement/DashboardAnnouncements';

const DashboardMessages = () => {
  const currentSeller = useCurrentSeller();

  return (
    <div className="DashboardMessages">
      <CancelledAlert />
      {!isAlfa(currentSeller) && (
        <>
          <ReviewWarning />
          <DeliveryWarning />
        </>
      )}
      <LiableWarning />
      <RejectedWithdrawalWarning />
      <SettlementWarning />
      <DashboardAnnouncements />
    </div>
  );
};

export default DashboardMessages;
