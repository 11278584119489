import React from 'react';
import { MenuItem } from 'shared/bootstrap';
import { translate } from 'shared/utils/I18n';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';

const t = translate('insights.time_filter_dropdown');

export type TimeFilter = '6' | '12' | 'all';

interface Props {
  onSelect: (months: TimeFilter) => void;
  currentValue: TimeFilter;
}

function TimeFilterDropdown({ currentValue, onSelect }: Props) {
  return (
    <div className="tw-relative tw-z-10">
      <Menu>
        <MenuButton className="tw-text-md tw-rounded-full tw-border-[2px] tw-border-gray-400 tw-px-4 tw-py-1.5 tw-text-black hover:tw-bg-gray-300 active:tw-bg-gray-400">
          {currentValue === 'all'
            ? t('title.all_html')
            : t(`title.restricted_html`, { value: currentValue })}
          <span className="caret"></span>
        </MenuButton>
        <MenuItems className="tw-absolute tw-right-0 tw-top-full tw-mt-0.5 tw-rounded-lg tw-border tw-border-gray-300 tw-bg-white tw-py-1 tw-shadow-xl tw-shadow-gray-300">
          <MenuItem
            active={currentValue === '6'}
            onSelect={() => onSelect('6')}
          >
            {t('options.restricted', { value: 6 })}
          </MenuItem>
          <MenuItem
            active={currentValue === '12'}
            onSelect={() => onSelect('12')}
          >
            {t('options.restricted', { value: 12 })}
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            active={currentValue === 'all'}
            onSelect={() => onSelect('all')}
          >
            {t('options.all')}
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  );
}

export default TimeFilterDropdown;
