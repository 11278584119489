import { useState } from 'react';
import { debounce } from 'lodash';
import { nt } from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import calculateWithdrawalInfo, {
  WithdrawalInfo,
} from './calculateWithdrawalInfo';
import calculateMinimumWithdrawalAmount from './calculateMinimumWithdrawalAmount';
import { Seller } from 'types';

const ns = 'signup.onboarding.withdrawal_calculator';

const useWithdrawalCalculator = () => {
  const seller = useCurrentSeller() as Seller;
  const creditDecision = seller.creditDecision;
  const creditLimit = creditDecision.creditLimit
    ? parseFloat(creditDecision.creditLimit)
    : 0.0;
  const initialWithdrawAmount = Math.floor(creditLimit / 100) * 100;
  const minimumAmount = calculateMinimumWithdrawalAmount({
    closingCommissionRate: parseFloat(creditDecision.closingCommissionRate),
    creditLimit: creditLimit,
  });
  const [amount, setAmount] = useState(initialWithdrawAmount);
  const [error, setError] = useState<string | null>(null);

  const [withdrawalInfo, setWithdrawalInfo] = useState<WithdrawalInfo>(() =>
    calculateWithdrawalInfo(initialWithdrawAmount, seller)
  );

  const validate = (value: number) => {
    if (value < minimumAmount) {
      return nt(ns, 'errors.minimum', {
        amount: toEuro(minimumAmount, 0),
      });
    }

    if (value > creditLimit) {
      return nt(ns, 'errors.maximum', {
        amount: toEuro(creditLimit, 0),
      });
    }

    return null;
  };

  const updateWithdrawalInfo = (value: number) => {
    setWithdrawalInfo(calculateWithdrawalInfo(value, seller));
  };

  const debouncedUpdateWithdrawalInfo = debounce(updateWithdrawalInfo, 250);

  const changeInputValue = (value: number | null) => {
    if (value === null) {
      return;
    }

    setAmount(value);
    const errorMessage = validate(value);
    setError(errorMessage);

    if (!errorMessage) {
      debouncedUpdateWithdrawalInfo(value);
    }
  };

  return {
    amount,
    changeInputValue,
    error,
    seller,
    withdrawalInfo,
  };
};

export default useWithdrawalCalculator;
