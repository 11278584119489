import React from 'react';
import { Seller } from 'types';
import { isAlfa } from 'models/Seller';
import Video from './Video';

interface Props {
  seller: Seller;
}

const videoKind = (seller: Seller) => {
  if (isAlfa(seller)) {
    return 'generalIntroductionAlfa';
  }

  return 'platformWalkthrough';
};

const ProductVideo = ({ seller }: Props) => {
  return <Video kind={videoKind(seller)} />;
};

export default ProductVideo;
