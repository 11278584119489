import React from 'react';

import { Col, Row } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import InformationField from 'shared/components/InformationField';

import ChangePasswordRequest from 'app/containers/profile/ChangePasswordRequest';
import CompanyInformation from './CompanyInformation';
import ChangeEmailRequest from './ChangeEmailRequest';
import ChangeFlorynInvoiceCcEmail from './ChangeFlorynInvoiceCcEmail';
import TwoFactorSettings from './TwoFactorSettings';
import FinanceAllInvoices from './FinanceAllInvoices';
import ProfileContract from './ProfileContract';
import { toPercentage } from 'shared/utils/Helpers';
import { isAlfa } from 'models/Seller';

import { AlfaSeller, Company, Seller, User } from 'types';
import IconTooltip from 'shared/components/IconTooltip';

interface Props {
  companies: Company[];
  sellers: Seller[];
  user: User;
}

const AccountInformation = ({ companies, sellers, user }: Props) => {
  if (!companies || !sellers) {
    return <LoadingAnimation />;
  }

  const { fullName, email } = user;

  const seller = sellers[0] as AlfaSeller;

  return (
    <div className="c-account-information">
      <h3>{I18n.nt('app.profilePage', 'profile')}</h3>
      <div className="profile-page-section">
        {sellers.map((seller) => (
          <CompanyInformation
            key={seller.id}
            company={
              companies.find(
                (company) => company.id === seller.company
              ) as Company
            }
            seller={seller}
          />
        ))}
      </div>

      <div className="profile-page-section">
        <h4>{I18n.nt('app.profile', 'userFields')}</h4>
        <Row>
          <Col lg={4}>
            <InformationField field="full_name" value={fullName} model="user" />
          </Col>
          <Col lg={4}>
            <InformationField field="email" value={email} model="user" />
          </Col>
          <Col lg={4}>
            <InformationField field="phone" value={user.phone} model="user" />
          </Col>
        </Row>
      </div>

      {seller.productVersion === 'alfa' && (
        <div className="profile-page-section">
          <div className="tw-mb-4 tw-flex tw-gap-1">
            <h4 className="tw-my-0">{I18n.nt('app.profilePage', 'rates')}</h4>
            <IconTooltip
              iconClassName="tw-text-gray-500"
              tooltipText={I18n.nt('app.profilePage', 'rates_info')}
            />
          </div>

          <Row>
            <Col lg={4}>
              <InformationField
                field="advance_rate"
                value={toPercentage(seller.advanceRate, 0)}
                model="seller"
              />
            </Col>
            <Col lg={4}>
              <InformationField
                field="fixed_rate"
                value={toPercentage(seller.fixedRate)}
                model="seller"
              />
            </Col>
            <Col lg={4}>
              <InformationField
                field="variable_rate"
                value={toPercentage(seller.variableRate)}
                model="seller"
              />
            </Col>
          </Row>
        </div>
      )}

      <div className="profile-page-section">
        <Row>
          <Col lg={6}>
            <ProfileContract />
          </Col>
          {isAlfa(seller) && (
            <Col lg={6}>
              <FinanceAllInvoices />
            </Col>
          )}
        </Row>
      </div>

      <div className="profile-page-section two-factor">
        <Row>
          <ChangePasswordRequest />
          <TwoFactorSettings
            enabled={user.twoFactorEnabled}
            phone={user.phone}
          />
        </Row>
      </div>

      <div className="profile-page-section">
        <Row>
          <ChangeEmailRequest />
          <ChangeFlorynInvoiceCcEmail />
        </Row>
      </div>
    </div>
  );
};

export default AccountInformation;
