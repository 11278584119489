import React, { ReactNode } from 'react';
import { isFunction } from 'lodash';
import AmountField from './AmountField';
import DebtCollectionPanel from './DebtCollectionPanel';
import Chart from './Chart';

interface LabeledAmount {
  amount: number;
  translationKey: string;
}

type RenderPanel = ReactNode | (() => ReactNode);

interface Props {
  chartPercentage: number;
  renderBottomLeft: RenderPanel;
  renderBottomRight: RenderPanel;
  topLeft: LabeledAmount;
}

const DashboardSummaryLayout = ({
  chartPercentage,
  renderBottomLeft,
  renderBottomRight,
  topLeft,
}: Props) => (
  <div className="DashboardSummaryLayout">
    <div className="inner">
      <div className="top-panel">
        <div className="balance-panel">
          <AmountField
            amount={topLeft.amount}
            translationKey={topLeft.translationKey}
            includeColon={false}
          />
        </div>
        <DebtCollectionPanel />
      </div>
      <Chart chartPercentage={chartPercentage} />
      <div className="tw-grid tw-grid-cols-2">
        {isFunction(renderBottomLeft) ? renderBottomLeft() : renderBottomLeft}
        {isFunction(renderBottomRight)
          ? renderBottomRight()
          : renderBottomRight}
      </div>
    </div>
  </div>
);

export default DashboardSummaryLayout;
