import React from 'react';
import { Alert, Button, Glyphicon } from 'shared/bootstrap';

import * as I18n from 'shared/utils/I18n';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import LoadingError from 'shared/components/LoadingError';
import IconTooltip from 'shared/components/IconTooltip';
import { MainUser, User } from 'types';
import { isCharlieWithCreditProduct } from 'models/Seller';
import useUsers from './useUsers';
import DeleteModal from './DeleteModal';
import UserFormModal from './UserFormModal';
import ChangeUserPermissionsModal from './ChangeUserPermissionsModal';

interface UserColumnProps {
  user: User;
}

const UserColumn = ({ user }: UserColumnProps) => (
  <td>
    <div className="info">
      <div>
        <strong>{user.fullName}</strong>
      </div>
      <div>{user.email}</div>
      {user.lastActivityAt && (
        <div>
          {I18n.attribute('user', 'last_activity_at')} {user.lastActivityAt}
        </div>
      )}
    </div>
  </td>
);

interface SecondaryUserHintProps {
  mainUser: MainUser;
}

const SecondaryUserHint = ({ mainUser }: SecondaryUserHintProps) => {
  const text = I18n.nt(
    'charlie.users',
    'tooltip',
    {},
    { main_user_name: mainUser.fullName }
  );

  return <IconTooltip tooltipText={text} />;
};

const UserList = () => {
  const {
    action,
    afterSavePermissions,
    createStatus,
    createUser,
    currentUser,
    deleteStatus,
    handleDeleteConfirm,
    inviteNewUsers,
    mainUser,
    mainUserIsLoggedIn,
    resetAction,
    seller,
    setActionAndUser,
    status,
    users,
  } = useUsers();

  switch (status) {
    case 'loading':
      return <LoadingAnimation />;

    case 'error':
      return <LoadingError />;

    case 'loaded':
      return (
        <div className="c-users-information">
          {deleteStatus === 'error' && (
            <Alert type="danger">{I18n.nt('common', 'server_error')}</Alert>
          )}

          <div className="sm:tw-flex sm:tw-items-center sm:tw-justify-between">
            <h3>{I18n.nt('charlie.users', 'title')}</h3>
            <div className="tw-flex tw-items-center tw-gap-2">
              {!mainUserIsLoggedIn && <SecondaryUserHint mainUser={mainUser} />}
              {inviteNewUsers && (
                <Button
                  className="btn-primary add-user"
                  disabled={action !== '' || !mainUserIsLoggedIn}
                  onClick={() => setActionAndUser(['create', null])}
                >
                  <Glyphicon glyph="plus" className="tw-mr-2" />
                  {I18n.nt('charlie.users', 'new_user')}
                </Button>
              )}
            </div>
          </div>

          <hr />

          <table>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <UserColumn user={user} />
                  {mainUserIsLoggedIn && mainUser.id !== user.id && (
                    <td>
                      <div className="actions">
                        {isCharlieWithCreditProduct(seller) && (
                          <Button
                            className="btn-tertiary-inverted"
                            disabled={action !== ''}
                            onClick={() =>
                              setActionAndUser(['changePermissions', user])
                            }
                          >
                            {I18n.nt(
                              'charlie.users.change_permissions',
                              'button_title'
                            )}
                          </Button>
                        )}
                        <Button
                          className="btn-danger"
                          disabled={action !== ''}
                          onClick={() => setActionAndUser(['delete', user])}
                        >
                          {I18n.nt('charlie.users.delete_user', 'button_title')}
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <ChangeUserPermissionsModal
            afterSavePermissions={afterSavePermissions}
            currentUser={currentUser}
            onHide={resetAction}
            show={action === 'changePermissions'}
          />

          <DeleteModal
            handleDeleteConfirm={handleDeleteConfirm}
            onHide={resetAction}
            show={action === 'delete'}
          />

          {inviteNewUsers && (
            <UserFormModal
              createStatus={createStatus}
              createUser={createUser}
              onHide={resetAction}
              show={action === 'create'}
            />
          )}
        </div>
      );

    default:
      return null;
  }
};

export default UserList;
