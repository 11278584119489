import React, { ReactNode } from 'react';
import {
  Tab as HeadlessTab,
  TabGroup as HeadlessTabGroup,
  TabList as HeadlessTabList,
  TabPanel as HeadlessTabPanel,
  TabPanels as HeadlessTabPanels,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';

interface TabInfo {
  content: ReactNode;
  key: string;
  title: string;
}

interface Props {
  hideTabListOnMobile?: boolean;
  currentTab: string;
  onSelectTab?: (tab: string) => void;
  tabs: TabInfo[];
}

const tabClassName = ({ selected }: { selected: boolean }) =>
  classNames(
    'tw-py-2 tw-px-4 tw-mb-[-1px] tw-mr-[2px] focus:tw-outline-2 focus:tw-outline-floryn-middle-blue',
    selected
      ? 'tw-border tw-border-gray-300 tw-border-b-white tw-rounded-sm'
      : 'tw-border tw-border-transparent tw-text-floryn-light-blue hover:tw-bg-gray-200 hover:tw-border-t hover:tw-border-t-gray-200 hover:tw-text-floryn-light-blue-hover'
  );

const getSelectedTabIndex = (tabKey: string, tabs: TabInfo[]) => {
  const selectedIndex = tabs.findIndex((tab) => tab.key === tabKey);

  return selectedIndex === -1 ? 0 : selectedIndex;
};

const Tabs = ({
  currentTab,
  onSelectTab,
  tabs,
  hideTabListOnMobile = false,
}: Props) => {
  const activeTabIndex = getSelectedTabIndex(currentTab, tabs);

  const handleChange = (index: number) => {
    if (onSelectTab) {
      onSelectTab(tabs[index].key);
    }
  };

  return (
    <HeadlessTabGroup selectedIndex={activeTabIndex} onChange={handleChange}>
      <HeadlessTabList
        className={classNames(
          'tw-border-b tw-border-gray-300',
          hideTabListOnMobile && 'tw-hidden md:tw-block'
        )}
      >
        {tabs.map(({ key, title }) => (
          <HeadlessTab className={tabClassName} key={key}>
            {title}
          </HeadlessTab>
        ))}
      </HeadlessTabList>
      <HeadlessTabPanels
        className={classNames(hideTabListOnMobile ? 'md:tw-mt-8' : 'tw-mt-8')}
      >
        {tabs.map(({ content, key }, i) => (
          <HeadlessTabPanel key={key} static>
            <Transition
              show={activeTabIndex === i}
              enter="tw-transition-opacity tw-duration-150"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition-opacity tw-duration-150"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div>{content}</div>
            </Transition>
          </HeadlessTabPanel>
        ))}
      </HeadlessTabPanels>
    </HeadlessTabGroup>
  );
};

export default Tabs;
