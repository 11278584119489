import React from 'react';

interface DescriptionListProps {
  children: React.ReactNode;
}

export const DescriptionList = ({ children }: DescriptionListProps) => (
  <dl className="tw-mb-0 tw-flex tw-flex-col tw-gap-4">{children}</dl>
);

interface DescriptionProps {
  details: string;
  term: string;
}

export const Description = ({ details, term }: DescriptionProps) => (
  <div className="md:tw-grid md:tw-grid-cols-3 md:tw-gap-4">
    <dt className="tw-font-normal">{term}</dt>
    <dd className="tw-font-bold md:tw-col-span-2">{details}</dd>
  </div>
);
