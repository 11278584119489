import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { ReduxState } from 'types';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import {
  CurrentCommitment,
  NextDebtCollection,
  Restriction,
} from 'charlie/modules/dashboardSlice';

export const useSelectDashboardFromRedux = () => {
  const { dashboard } = useSelectorWithShallowCompare((state: ReduxState) => ({
    dashboard: state.charlie.dashboard.payload,
  }));

  return dashboard;
};

type DashboardDetails = {
  availableBalance: number;
  baseInterestRate: number;
  clientBankAccount?: string;
  creditLimit: number;
  currentBalance: number;
  currentCommitment?: CurrentCommitment;
  euriborRate: number;
  interestRate: number;
  nextDebtCollection?: NextDebtCollection;
  nextRestriction?: Restriction;
  settlement: number;
  settlementCalculated: boolean;
};

export const useDashboardDetails = (): DashboardDetails | null => {
  const dashboard = useSelectDashboardFromRedux();
  const seller = useCurrentSeller();

  if (!dashboard) {
    return null;
  }

  const availableBalance = parseFloat(dashboard.availableBalance);
  const creditLimit = parseFloat(dashboard.creditLimit);
  const currentBalance = parseFloat(dashboard.currentBalance);
  const interestRate = parseFloat(dashboard.interestRate);
  const baseInterestRate = parseFloat(dashboard.baseInterestRate);
  const euriborRate = parseFloat(dashboard.euriborRate);
  const settlement = parseFloat(dashboard.settlement);
  const {
    charlieCollectionMethod,
    currentCommitment,
    nextDebtCollection,
    nextRestriction,
    settlementCalculated,
  } = dashboard;

  const clientBankAccount =
    charlieCollectionMethod === 'client_bank_account'
      ? seller.clientBankAccount
      : undefined;

  return {
    availableBalance,
    baseInterestRate,
    clientBankAccount,
    creditLimit,
    currentBalance,
    currentCommitment,
    euriborRate,
    interestRate,
    nextDebtCollection,
    nextRestriction,
    settlement,
    settlementCalculated,
  };
};
