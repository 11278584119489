import React from 'react';
import AmountField from 'charlie/components/AccountSummary/AmountField';
import { Restriction } from 'charlie/modules/dashboardSlice';
import IconTooltip from 'shared/components/IconTooltip';
import { dateFormat } from 'shared/utils/date';
import { nt } from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';

const ns = 'charlie.frontpage.account_summary';

interface Props {
  amount: number;
  nextRestriction?: Restriction;
  translationKey: string;
}

const BottomRight = ({ amount, nextRestriction, translationKey }: Props) => (
  <div className="tw-flex tw-justify-end tw-gap-1 tw-text-right">
    <AmountField amount={amount} translationKey={translationKey}>
      {nextRestriction && (
        <span>
          <IconTooltip
            tooltipText={nt(ns, 'explain_restriction_html', {
              date: dateFormat(nextRestriction.date),
              new_credit_limit: toEuro(nextRestriction.newLimit),
              step_amount: toEuro(nextRestriction.stepAmount),
            })}
            iconSize="sm"
          />
        </span>
      )}
    </AmountField>
  </div>
);

export default BottomRight;
