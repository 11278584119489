import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { SELLER_ONBOARDING } from 'app/routes';
import useEnsureFullSeller from 'shared/hooks/useEnsureFullSeller';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import OnboardingStep from './OnboardingStep';
import QuestionsStep from './Questions/QuestionsStep';
import ProductInformationStep from './ProductInformation/ProductInformationStep';

const OnboardingPage = () => {
  const { seller } = useEnsureFullSeller();

  if (!seller) {
    return <LoadingAnimation />;
  }

  const canSeeProductInformation =
    Boolean(seller.contract) ||
    seller.showProposalSummary ||
    seller.showAutomatedOffer;

  return (
    <Switch>
      {canSeeProductInformation && (
        <Route
          path={`${SELLER_ONBOARDING}/product-informatie`}
          component={ProductInformationStep}
        />
      )}
      <Route
        path={`${SELLER_ONBOARDING}/questions/:kind`}
        component={QuestionsStep}
      />
      <Route path={SELLER_ONBOARDING} component={OnboardingStep} />
    </Switch>
  );
};

export default OnboardingPage;
