import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useFlorynPayIdentificationStatus from './useFlorynPayIdentificationStatus';

const ns = 'charlie.frontpage.floryn_pay_identification_status';

interface Props {
  parentPath: string;
}

const FlorynPayIdentificationStatus = ({ parentPath }: Props) => {
  const { close, status } = useFlorynPayIdentificationStatus(parentPath);

  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-mb-8 tw-flex tw-flex-col">
          <div>{I18n.nt(ns, `${status}_html`)}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FlorynPayIdentificationStatus;
