import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Alert, AlertType } from './Alert';

interface Props {
  actionText: string;
  actionUrl: string;
  children: ReactNode;
  type: AlertType;
}

const AlertWithLink = ({ actionText, actionUrl, children, type }: Props) => {
  const linkClasses = classnames(
    'tw-font-medium tw-font-heading tw-whitespace-nowrap hover:tw-no-underline',
    {
      'tw-text-red-800 hover:tw-text-red-950 focus:tw-text-red-950':
        type === 'danger',
      'tw-text-blue-900 hover:tw-text-blue-950 focus:tw-text-blue-950':
        type === 'info',
      'tw-text-green-800 hover:tw-text-green-950 focus:tw-text-green-950':
        type === 'success',
      'tw-text-yellow-800 hover:tw-text-yellow-950 focus:tw-text-yellow-950':
        type === 'warning',
    }
  );

  return (
    <Alert type={type}>
      <div className="tw-flex tw-flex-col tw-justify-between tw-gap-3 md:tw-flex-row md:tw-gap-4">
        <div>{children}</div>
        <div className="tw-flex tw-items-center tw-justify-end">
          <Link to={actionUrl} className={linkClasses}>
            {actionText}
            <span aria-hidden="true" className="tw-pl-2">
              &rarr;
            </span>
          </Link>
        </div>
      </div>
    </Alert>
  );
};

export default AlertWithLink;
