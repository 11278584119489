import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  url: string;
}

const Logo = ({ url }: Props) => {
  const today = new Date();
  const christmas = today.getMonth() === 11 && today.getDate() >= 14;
  const logo = christmas ? 'logo-christmas' : 'logo';

  return (
    <Link to={url}>
      <img alt="Floryn" src={`/images/${logo}.svg`} className="tw-w-[130px]" />
    </Link>
  );
};

export default Logo;
