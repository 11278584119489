import { nt } from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import React from 'react';
import RepaymentAndInterestChart from './RepaymentAndInterestChart';
import CurrentDebtChart from './CurrentDebtChart';

const ns = 'signup.onboarding.withdrawal_calculator';

const charlieCalculator = ({ seller, withdrawalInfo }) => {
  return (
    <>
      <ul className="tw-mb-0 tw-flex tw-list-inside tw-flex-col tw-gap-2">
        <li>
          {nt(ns, `outpayment_amount_html`, {
            amount: toEuro(withdrawalInfo.outpaymentAmount),
          })}
        </li>
        {withdrawalInfo.withdrawalCosts !== 0 && (
          <li>
            {nt(ns, 'withdrawal_costs_html', {
              amount: toEuro(withdrawalInfo.withdrawalCosts),
            })}
          </li>
        )}
        {withdrawalInfo.closingCommissionCosts !== 0 && (
          <li>
            {nt(ns, 'closing_commission_costs_html', {
              amount: toEuro(withdrawalInfo.closingCommissionCosts),
            })}
          </li>
        )}
        <li>
          {nt(ns, 'debt_collection_amount_html', {
            amount: toEuro(withdrawalInfo.debtCollectionAmount),
          })}
        </li>
        {withdrawalInfo.totalInterest !== 0 && (
          <li>
            {nt(ns, 'total_interest_html', {
              amount: toEuro(withdrawalInfo.totalInterest),
            })}
            {seller.hasEuribor && <>{' ' + nt(ns, 'including_euribor')}</>}
          </li>
        )}
        <li>{nt(ns, 'calculator_info')}</li>
      </ul>
      {withdrawalInfo.paymentsValid && (
        <>
          <div>
            {nt(ns, 'repayment_info', {
              count: withdrawalInfo.payments.length,
            })}
          </div>

          <RepaymentAndInterestChart withdrawalInfo={withdrawalInfo} />
          <CurrentDebtChart payments={withdrawalInfo.payments} />
        </>
      )}
    </>
  );
};

export default charlieCalculator;
