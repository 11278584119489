import React from 'react';
import SellerActions from '../SellerActions';
import StatementsAction from './StatementsAction';
import { AlfaSeller, Seller } from 'types';
import { exampleInvoicesStatus, statementsStatus } from 'models/Onboarding';
import { isAlfa, isCharlie } from 'models/Seller';
import ExampleInvoices from '../ExampleInvoices';
import FinancialDashboardStatus from '../FinancialDashboardStatus';
import GoogleReviews from '../GoogleReviews';
import SubmittedVideo from './SubmittedVideo';
import { nt } from '../utils';
import InternalAccountDetection from './InternalAccountDetection';

const ns = 'signup.onboarding.without_contract';
const t = nt(ns);

interface Props {
  basePath: string;
  seller: Seller;
}

const WithoutQuestions = ({ basePath, seller }: Props) => (
  <>
    <SellerActions>
      <StatementsAction basePath={basePath} status={statementsStatus(seller)} />
      {isAlfa(seller) && (
        <ExampleInvoices
          basePath={basePath}
          status={exampleInvoicesStatus(seller as AlfaSeller)}
        />
      )}
    </SellerActions>

    <InternalAccountDetection />

    <div>{t('we_will_contact_you')}</div>
    <SubmittedVideo seller={seller} />
    <div className="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2">
      <FinancialDashboardStatus />
      <GoogleReviews />
    </div>
    <div className="tw-mt-2">
      <h3 className="tw-text-lg md:tw-mb-4 md:tw-text-bootstrap-h3">
        {t('speed_up_your_signup')}
      </h3>
      <div>
        {t('optional_steps')} {isAlfa(seller) && t('optional_steps_alfa')}
        {isCharlie(seller) && t('optional_steps_charlie')}
      </div>
    </div>
  </>
);

export default WithoutQuestions;
