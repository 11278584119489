import React from 'react';
import Badge from './Badge';
import Panel from './Panel';
import UploadedFileWithMessage from './UploadedFileWithMessage';
import { Attachment } from './types';
import { translate } from 'shared/utils/I18n';

const t = translate('review_wizard.unrecognised_files_panel');

interface Props {
  onDeleteAttachment: (id: number) => void;
  type: 'warning' | 'error';
  unrecognisedFiles: Attachment[];
}

const UnrecognisedFilesPanel = ({
  onDeleteAttachment,
  type,
  unrecognisedFiles,
}: Props) => {
  const isWarning = type === 'warning';
  return (
    <Panel className="rw-sidebar rw-sidebar--right rw-unrecognised-files">
      <div className="rw-sidebar rw-sidebar--left rw-gap-12">
        <div>
          {isWarning ? (
            <Badge status="call-us" />
          ) : (
            <Badge status="warning" glyph="file" />
          )}
        </div>
        <div className="rw-space-y-16">
          <div className="rw-panel__title">
            {isWarning ? t('warning_title') : t('error_title')}
          </div>
          <div>{isWarning ? t('warning_hint') : t('error_hint')}</div>
          <div>
            <ul className="tw-ml-0 tw-flex tw-flex-col tw-gap-2">
              {unrecognisedFiles.map((attachment) => (
                <UploadedFileWithMessage
                  key={attachment.id}
                  onDeleteAttachment={onDeleteAttachment}
                  uploadedFile={attachment}
                  type={type}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default UnrecognisedFilesPanel;
