import React from 'react';
import { DialogTitle } from '@headlessui/react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const ModalHeader = ({ children, className, ...props }: Props) => {
  return (
    <DialogTitle
      as="h4"
      {...props}
      className={['modal-title', className].join(' ')}
    >
      {children}
    </DialogTitle>
  );
};

export default ModalHeader;
