import React from 'react';
import {
  dateFormat,
  formatIban,
  toEuro,
  toPercentage,
} from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import DataRow from './DataRow';
import { useDashboardDetails } from './useDashboard';

const ns = 'charlie.frontpage.account_summary';

const t = (key: string) => I18n.nt(ns, key);

const MobileSummaryDetails = () => {
  const dashboardDetails = useDashboardDetails();

  if (!dashboardDetails) {
    return null;
  }

  const {
    baseInterestRate,
    clientBankAccount,
    creditLimit,
    currentBalance,
    currentCommitment,
    euriborRate,
    interestRate,
    nextDebtCollection,
    nextRestriction,
    settlement,
    settlementCalculated,
  } = dashboardDetails;

  const dataRows: [string, string | string[]][] = [
    [t('credit_limit'), toEuro(creditLimit)],
  ];

  if (nextRestriction) {
    dataRows.push([
      t('next_restriction'),
      [dateFormat(nextRestriction.date), toEuro(-nextRestriction.stepAmount)],
    ]);
  }

  if (settlement < 0) {
    dataRows.push([t('still_to_receive'), toEuro(-settlement)]);
  } else if (settlement > 0) {
    dataRows.push([t('still_to_pay'), toEuro(settlement)]);
  } else if (currentBalance > 0) {
    dataRows.push([t('still_to_receive'), toEuro(currentBalance)]);
  } else {
    // NB: current balance is represented as a negative value
    dataRows.push([t('current_balance'), toEuro(currentBalance)]);
  }

  if (clientBankAccount) {
    dataRows.push([
      t('client_bank_account'),
      formatIban(clientBankAccount) || '',
    ]);
  }

  if (nextDebtCollection) {
    dataRows.push([
      t('next_debtcollection'),
      [dateFormat(nextDebtCollection.date), toEuro(nextDebtCollection.amount)],
    ]);
  }

  if (currentCommitment) {
    dataRows.push([
      settlementCalculated ? t('settlement') : t('commitment'),
      [currentCommitment.message, toEuro(currentCommitment.amount)],
    ]);
  }

  const interestValues = [toPercentage(interestRate)];

  if (euriborRate > 0) {
    interestValues.push(`${t('base_rate')} ${toPercentage(baseInterestRate)}`);
    interestValues.push(`${t('euribor_rate')} ${toPercentage(euriborRate)}`);
  }

  dataRows.push([t('interest_per_month'), interestValues]);

  return (
    <div className="tw-mt-2 tw-divide-y tw-divide-gray-500">
      {dataRows.map(([title, value]) => (
        <DataRow key={title} title={title} value={value} />
      ))}
    </div>
  );
};

export default MobileSummaryDetails;
