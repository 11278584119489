import React from 'react';
import { TranslateOptions } from 'i18n-js';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';
import usePoll from './usePoll';
import { FlorynPayPaymentResponse } from './types';

const ns = 'charlie.frontpage.floryn_pay_add_payment.processing_step';
const t = (key: string, options?: TranslateOptions) =>
  I18n.nt(ns, key, options);

interface Props {
  florynPayPaymentId: number;
}

const ProcessingStep = ({ florynPayPaymentId }: Props) => {
  const callback = (response: FlorynPayPaymentResponse) => {
    const florynPayPayment = response.florynPayPayment;

    if (
      florynPayPayment.status === 'consent_pending' &&
      florynPayPayment.swanConsentUrl
    ) {
      location.href = florynPayPayment.swanConsentUrl;
      return false;
    }

    return true;
  };

  usePoll<FlorynPayPaymentResponse>({
    path: `/api/floryn_pay_payments/${florynPayPaymentId}`,
    timeMs: 500,
    callback,
  });

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
      <LoadingAnimation />
      <div className="tw-italic">{t('preparing_payment')}</div>
      <div>{t('next_step')}</div>
    </div>
  );
};

export default ProcessingStep;
