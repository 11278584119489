import React from 'react';
import DesktopSummary from './DesktopSummary';
import MobileSummary from './MobileSummary';

const CharlieSummary = () => {
  return (
    <>
      <div className="tw-hidden md:tw-block">
        <DesktopSummary />
      </div>
      <div className="md:tw-hidden">
        <MobileSummary />
      </div>
    </>
  );
};

export default CharlieSummary;
