import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as AddStatementsStatus from '../components/ReviewWizard/AddStatementsStatus';
import { Attachment, Step } from '../components/ReviewWizard/types';
import { DebtorList, ReviewSheet } from '../components/ReviewWizard/modules';
import client from 'shared/utils/client';
import {
  CREATE_ATTACHMENT,
  CREATE_ATTACHMENT_FAILURE,
  CREATE_ATTACHMENT_SUCCESS,
} from 'hookForm/AttachmentsInput/module';
import { MinimalSeller, ReduxState, Review } from 'types';
import {
  submit,
  SubmitInput,
  SubmitStatus,
  validateSubmittable,
} from '../components/ReviewWizard/modules/submit';
import {
  LOAD_WITH_POTENTIAL_GAPS,
  loadWithPotentialGaps,
} from 'shared/modules/review';
import { keyBy } from 'lodash';
import Remote, { failureFromError } from 'types/Remote';
import { push } from 'redux-first-history';
import { CUSTOMER_APP_ROOT, INVOICES } from 'app/routes';
import { trackEvent } from 'namespaces/shared/utils/tracker';
import { currentSellerSelector } from 'shared/selectors';
import { isAlfa } from 'models/Seller';

interface LoadingState {
  loading: Remote<unknown>;
}

interface LoadedState {
  step: Step;
  addStatementsStatus: AddStatementsStatus.AddStatementsStatus;
  reviewSheet: ReviewSheet.ReviewSheet;
  reviewId: number;
  sellerComment: string;
  debtorList: DebtorList.DebtorList;
  entities: any;
  bankStatementAttachments: Attachment[];
  submitStatus: SubmitStatus;
}

const name = 'reviewWizard';

export type State = LoadingState & LoadedState;

export const initialState = {
  loading: { type: 'pending' },
  step: 'overview',
  addStatementsStatus: AddStatementsStatus.none(),
  reviewSheet: ReviewSheet.empty(),
  sellerComment: '',
  requiredNumberOfMonths: 3,
  reviewId: 0,
  debtorList: DebtorList.notApplicable(),
  entities: {},
  bankStatementAttachments: [],
  submitStatus: { type: 'notStarted' },
} as State;

export const submitWizard = createAsyncThunk<any, SubmitInput>(
  `${name}/submit`,
  (submitInput: SubmitInput, { getState, rejectWithValue, dispatch }) => {
    const result = validateSubmittable(submitInput);

    if (result.type === 'invalid') {
      trackEvent('ReviewWizard.invalid', { reason: result.reason });
      return rejectWithValue({ failedValidationReason: result.reason });
    }

    const state = getState() as ReduxState;
    const seller = currentSellerSelector(state) as MinimalSeller;

    return submit(submitInput)
      .then(() => trackEvent('ReviewWizard.submit'))
      .then(() =>
        dispatch(push(isAlfa(seller) ? INVOICES : CUSTOMER_APP_ROOT))
      );
  }
);

export const deleteAttachment = createAsyncThunk(
  `${name}/deleteAttachment`,
  (attachmentId: number, { getState, dispatch }) => {
    const state = getState() as ReduxState;
    const seller = currentSellerSelector(state) as MinimalSeller;
    const ids = state.charlie.review.payload?.bankStatementAttachmentIds ?? [];

    client('DELETE', `/api/attachments/${attachmentId}`).then(() =>
      dispatch(
        loadWithPotentialGaps(
          seller.id,
          ids.filter((i) => i !== attachmentId)
        )
      )
    );
  }
);

export const loadWithPotentialGapsForAllAttachments = createAsyncThunk(
  `${name}/loadWithPotentialGapsForAllAttachments`,
  async (newIds: number[], { getState, dispatch }) => {
    const state = getState() as ReduxState;
    const seller = currentSellerSelector(state) as MinimalSeller;
    const existingIds =
      state.charlie.review.payload?.bankStatementAttachmentIds ?? [];

    await dispatch(
      loadWithPotentialGaps(seller.id, [...newIds, ...existingIds])
    );

    // Close the modal after uploading bank statements and loading the potential gaps.
    // * Close the modal after the potential gaps are loaded to make the ui predictable for system tests.
    // * loadWithPotentialGapsForAllAttachments is called after bank statements are uploaded.
    dispatch(closeModal());
  }
);

const reducers = {
  setOverrideWarnings: (state: State, action: PayloadAction<boolean>) => {
    state.reviewSheet = ReviewSheet.setOverrideWarnings(
      state.reviewSheet,
      action.payload
    );
  },

  toAdding: (state: State): State => {
    state.step = 'addStatements';
    return state;
  },

  toOverview: (state: State): State => {
    state.step = 'overview';
    return state;
  },

  toDebtorList: (state: State): State => {
    state.step = 'debtorList';
    return state;
  },

  updateAddStatementsStatus: (
    state: State,
    action: PayloadAction<AddStatementsStatus.AddStatementsStatus>
  ): State => {
    state.addStatementsStatus = action.payload;
    return state;
  },

  updateSellerComment: (state: State, action: PayloadAction<string>): State => {
    state.sellerComment = action.payload;
    return state;
  },

  submitDebtorList: (
    state: State,
    action: PayloadAction<Partial<DebtorList.Task>>
  ): State => {
    state.debtorList = DebtorList.toDebtorList(action.payload);
    state.step = 'overview';
    return state;
  },

  closeModal: (state: State) => {
    state.step = 'overview';
    state.addStatementsStatus = AddStatementsStatus.none();

    return state;
  },
};

const addAttachmentCases = (builder) =>
  builder
    .addCase(CREATE_ATTACHMENT, (state, { uuid, upload, fieldName }) => {
      if (fieldName !== 'bankStatementAttachments') {
        return;
      }

      state.addStatementsStatus = AddStatementsStatus.addUpload(
        state.addStatementsStatus,
        uuid,
        upload
      );
    })
    .addCase(
      CREATE_ATTACHMENT_SUCCESS,
      (state, { payload, uuid, upload, fieldName }) => {
        if (fieldName !== 'bankStatementAttachments') {
          return;
        }

        state.addStatementsStatus = AddStatementsStatus.fulfillUpload(
          state.addStatementsStatus,
          uuid,
          upload
        );
        state.reviewSheet = ReviewSheet.addUpload(state.reviewSheet, {
          ...payload,
          deletionStatus: { type: 'not_asked' },
        });
      }
    )
    .addCase(
      CREATE_ATTACHMENT_FAILURE,
      (state, { payload, upload, uuid, fieldName }) => {
        if (fieldName !== 'bankStatementAttachments') {
          return;
        }

        state.addStatementsStatus = AddStatementsStatus.failUpload(
          state.addStatementsStatus,
          uuid,
          upload,
          payload?.validationErrors?.fileName ?? 'unknown'
        );
      }
    );

function setDeletionStatus(state, attachmentId, deletionStatus) {
  const check = (items) => {
    items.forEach(({ attachment }) => {
      if (attachment?.id === attachmentId) {
        attachment.deletionStatus = deletionStatus;
      }
    });
  };

  check(state.reviewSheet.ibanTasks);
  check(state.reviewSheet.newUploads);
}

const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: reducers,
  extraReducers: (builder) => {
    addAttachmentCases(builder)
      .addCase('charlie.reviews.LOAD_FAILURE', (state: State, action: any) => {
        state.loading = failureFromError(action);
      })
      .addCase('charlie.reviews.LOAD_SUCCESS', (state: State, action: any) => {
        state.loading = { type: 'success', response: {} };

        // the action variable is not typed and cannot be reliably typed without
        // new complex decoding logic to type-safely parse the API response. For
        // now, let's assume we know its shape.
        const review: Review | null = action.payload.review;
        if (!review) return;

        const attachments = action.payload.entities.attachments || {};
        const bankStatementAttachments = review.bankStatementAttachmentIds.map(
          (id) => ({
            ...attachments[id],
            deletionStatus: { type: 'not_asked' },
          })
        );

        state.reviewId = review.id;
        state.reviewSheet = ReviewSheet.fromReview(
          review,
          bankStatementAttachments
        );

        // FIXME: work-around for the hook-form version of the AttachmentInput expecting objects instead of ids.
        review.debtorLists = review.debtorLists?.map((id) => attachments[id]);

        state.debtorList = DebtorList.fromReview(review);
        state.sellerComment = review.commentsBySeller;
      })
      .addCase(deleteAttachment.pending, (state, { meta }) => {
        setDeletionStatus(state, meta.arg, { type: 'pending' });
      })
      .addCase(deleteAttachment.fulfilled, (state, { meta, payload }) => {
        setDeletionStatus(state, meta.arg, {
          type: 'success',
          response: payload,
        });
      })
      .addCase(deleteAttachment.rejected, (state, { meta }) => {
        setDeletionStatus(state, meta.arg, {
          type: 'failure',
          error: 'bad_status',
        });
      })
      .addCase(
        LOAD_WITH_POTENTIAL_GAPS.FAILURE,
        (state: State, action: any) => {
          state.loading = failureFromError(action);
        }
      )
      .addCase(
        LOAD_WITH_POTENTIAL_GAPS.SUCCESS,
        (state: State, action: any) => {
          state.loading = { type: 'success', response: {} };

          // the action variable is not typed and cannot be reliably typed without
          // new complex decoding logic to type-safely parse the API response. For
          // now, let's assume we know its shape.
          const review: Review | null = action.payload.review;
          if (!review) return;

          const attachments = keyBy(
            action.payload.entities.filter(
              (e) => e.entityKind === 'Attachment'
            ),
            'id'
          );
          const bankStatementAttachments: Attachment[] =
            review.bankStatementAttachmentIds.map((id) => ({
              ...attachments[id],
              deletionStatus: { type: 'not_asked' },
            }));

          state.reviewId = review.id;
          state.reviewSheet = ReviewSheet.fromReview(
            review,
            bankStatementAttachments
          );

          // FIXME: work-around for the hook-form version of the AttachmentInput expecting objects instead of ids.
          review.debtorLists = review.debtorLists?.map((id) => attachments[id]);

          state.debtorList = DebtorList.fromReview(review);
          state.sellerComment = review.commentsBySeller;
        }
      )
      .addCase(submitWizard.pending, (state: State) => {
        state.submitStatus = { type: 'pending' };
      })
      .addCase(submitWizard.fulfilled, (state: State) => {
        state.submitStatus = initialState.submitStatus;
      })
      .addCase(
        submitWizard.rejected,
        (state: State, { error, payload }: any) => {
          let reason;

          if (error.name) {
            reason = 'other';
          } else if (payload?.failedValidationReason) {
            reason = payload.failedValidationReason;
          } else {
            // A failed submit due to a server error results in an action
            // that doesn't contain an error and payload.
            reason = 'server';
          }

          state.submitStatus = {
            type: 'failed',
            reason,
          };
        }
      );
  },
});

// Given any action, track using Segment where applicable. Can be used in Redux
// middleware as it matches on whole action names.
//
// This function will safely ignore anything it does not care about.
export const trackAction = (action) => {
  if (!action?.type) return;

  switch (action.type) {
    case `${name}/toAdding`:
      trackEvent('ReviewWizard.toAdding');
      break;
    case `${name}/updateAddStatementsStatus`:
      trackEvent('ReviewWizard.SelectDelivery', action.payload);
      break;
    case `${name}/deleteAttachment/pending`:
      trackEvent('ReviewWizard.deleteAttachment');
      break;
  }
};

export const {
  closeModal,
  setOverrideWarnings,
  toAdding,
  toOverview,
  updateAddStatementsStatus,
  updateSellerComment,
  toDebtorList,
  submitDebtorList,
} = slice.actions;
export default slice.reducer;
