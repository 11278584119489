import React from 'react';
import { Glyphicon } from 'shared/bootstrap';
import { Link } from 'react-router-dom';

import { Seller } from 'types';
import { SELLER_ONBOARDING } from 'app/routes';
import { nt } from 'shared/utils/I18n';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import DefaultSidebar from 'shared/components/DefaultSidebar';

import ClosingCommissionInfo from './ClosingCommisionInfo';
import DebtCollectionInfo from './DebtCollectionInfo';
import InterestInfo from './InterestInfo';
import ProposalSummary from './ProposalSummary';
import ServiceFeeInfo from './ServiceFeeInfo';
import WithdrawalCalculator from './WithdrawalCalculator';
import WithdrawalFeeInfo from './WithdrawalFeeInfo';
import WithdrawalForServiceFeeInfo from './WithdrawalForServiceFeeInfo';
import DashboardPreview from './DashboardPreview';
import RestrictionInfo from './RestrictionInfo';

const ns = 'signup.onboarding.product_information';

const ProductInformationStep = () => {
  const seller = useCurrentSeller() as Seller;

  return (
    <ContentWithSidebar sidebarChildren={<DefaultSidebar />}>
      <div className="ProductInformationStep">
        <div>
          <Link
            to={SELLER_ONBOARDING}
            className="btn btn-default btn-light-inverted GlyphButton"
          >
            <Glyphicon glyph="arrow-left" />
            {nt(ns, 'back')}
          </Link>
        </div>
        <div className="title">
          <h3 className="tw-mt-0 tw-text-lg md:tw-mb-4 md:tw-text-bootstrap-h3">
            {nt(ns, 'title', { company: seller.companyName })}
          </h3>
          <ProposalSummary />
        </div>
        <WithdrawalCalculator />
        <RestrictionInfo />
        <DebtCollectionInfo />
        <InterestInfo />
        <WithdrawalFeeInfo />
        <WithdrawalForServiceFeeInfo />
        <ServiceFeeInfo />
        <ClosingCommissionInfo />
        <DashboardPreview />
      </div>
    </ContentWithSidebar>
  );
};

export default ProductInformationStep;
