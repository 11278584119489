import { memoize } from 'lodash';
import * as routes from 'app/routes';
import { CUSTOMER_APP_ROOT } from 'app/routes';
import * as I18n from 'shared/utils/I18n';
import InvoiceComponent from 'app/components/seller/Invoice/index';
import Invoices from 'app/components/seller/Invoices/index';
import Charlie from 'charlie';

const getProductConfig = (productVersion) => {
  switch (productVersion) {
    case 'alfa':
      return {
        navBarLinks: [
          {
            path: CUSTOMER_APP_ROOT,
            title: I18n.nt('charlie.nav', 'overview'),
            icon: 'overview',
          },
          {
            path: routes.INVOICES,
            title: I18n.nt('app.nav', 'invoices'),
            icon: 'coin-euro',
          },
        ],
        routes: [
          { path: routes.INVOICE, component: InvoiceComponent, exact: true },
          {
            path: routes.INVOICE_TAB,
            component: InvoiceComponent,
            exact: true,
          },
          { path: routes.INVOICES, component: Invoices, exact: true },
          { path: CUSTOMER_APP_ROOT, component: Charlie },
        ],
      };
    case 'charlie':
    case 'delta':
      return {
        navBarLinks: [
          {
            path: CUSTOMER_APP_ROOT,
            title: I18n.nt('charlie.nav', 'overview'),
            icon: 'overview',
          },
        ],
        routes: [{ path: CUSTOMER_APP_ROOT, component: Charlie }],
      };

    default:
      throw Error(`Invalid productVersion: ${productVersion}`);
  }
};

export default memoize(getProductConfig);
