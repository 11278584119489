import React from 'react';

import * as ReviewSheet from './modules/ReviewSheet';
import IbanPanel from './IbanPanel';
import { translate } from 'shared/utils/I18n';
import DebtorListPanel from './DebtorListPanel';
import UnrecognisedFilesPanel from './UnrecognisedFilesPanel';
import * as DebtorList from './modules/DebtorList';
import UploadedFiles from './UploadedFiles';
import { Glyphicon } from 'shared/bootstrap';
import { SubmitStatus, SubmitInput } from './modules/submit';
import * as IbanTask from './modules/IbanTask';
import SubmitWarning from './SubmitWarning';
import { useCurrentSeller } from 'shared/hooks';

const t = translate('review_wizard.overview');

interface Props {
  reviewSheet: ReviewSheet.ReviewSheet;
  onSubmit: (data: SubmitInput) => void;
  onAdd: () => void;
  sellerComment: string;
  onSellerCommentChange: (comment: string) => void;
  onDeleteAttachment: (id: number) => void;
  onOpenDebtorList: () => void;
  setOverrideWarnings: (override: boolean) => void;
  debtorList: DebtorList.DebtorList;
  submitStatus: SubmitStatus;
}

const Overview = ({
  reviewSheet,
  onSubmit,
  setOverrideWarnings,
  onAdd,
  sellerComment,
  onDeleteAttachment,
  onSellerCommentChange,
  onOpenDebtorList,
  debtorList,
  submitStatus,
}: Props) => {
  const updateSellerComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    onSellerCommentChange(event.target.value);
  };

  const { id: sellerId } = useCurrentSeller();

  const filesWithWarnings = ReviewSheet.filesWithWarnings(reviewSheet);
  const filesWithErrors = ReviewSheet.filesWithErrors(reviewSheet);

  const submitBar = (
    <div className="rw-sidebar rw-sidebar--right rw-submit-bar">
      <div className="rw-override-warnings">
        {ReviewSheet.isOverridable(reviewSheet) && (
          <label className="rw-sidebar rw-sidebar--left rw-items-center rw-gap-12">
            <input
              type="checkbox"
              checked={reviewSheet.overrideWarnings}
              onChange={(e) => setOverrideWarnings(e.target.checked)}
            />{' '}
            <span>{t('override')}</span>
          </label>
        )}
      </div>
      <button
        className="btn btn-primary"
        onClick={() =>
          onSubmit({ debtorList, reviewSheet, sellerComment, sellerId })
        }
        disabled={submitStatus.type === 'pending' || filesWithErrors.length > 0}
      >
        {t('submit')}
      </button>
    </div>
  );

  return (
    <div className="rw-space-y-32">
      {submitStatus.type === 'failed' && (
        <SubmitWarning reason={submitStatus.reason}>{submitBar}</SubmitWarning>
      )}
      <div className="iban_panels">
        {ReviewSheet.map(reviewSheet, (ibanTask) => {
          if (
            IbanTask.hasWarnings(ibanTask) ||
            IbanTask.isUnrecognised(ibanTask)
          ) {
            return null;
          } else {
            return <IbanPanel key={ibanTask.iban} ibanTask={ibanTask} />;
          }
        })}
        {filesWithWarnings.length > 0 && (
          <UnrecognisedFilesPanel
            onDeleteAttachment={onDeleteAttachment}
            type="warning"
            unrecognisedFiles={filesWithWarnings}
          />
        )}
        {filesWithErrors.length > 0 && (
          <UnrecognisedFilesPanel
            onDeleteAttachment={onDeleteAttachment}
            type="error"
            unrecognisedFiles={filesWithErrors}
          />
        )}
        {DebtorList.isApplicable(debtorList) && (
          <DebtorListPanel
            debtorList={debtorList}
            onOpenDebtorList={onOpenDebtorList}
          />
        )}
      </div>

      <div>
        <button onClick={onAdd} className="share-more">
          <span className="share-more__title">
            <Glyphicon glyph="plus" className="plus" />
            <span>{t('add')}</span>
          </span>
          <span className="share-more__byline">{t('add_byline')}</span>
        </button>
      </div>

      <div>
        <label htmlFor="seller-comments">{t('comment')}</label>
        <textarea
          id="seller-comments"
          className="form-control"
          rows={3}
          placeholder={t('comment_placeholder')}
          value={sellerComment ?? ''}
          onChange={updateSellerComment}
        />
      </div>

      <div>
        <UploadedFiles
          onDeleteAttachment={onDeleteAttachment}
          uploadedFiles={ReviewSheet.attachments(reviewSheet)}
        />
      </div>
      {submitBar}
    </div>
  );
};

export default Overview;
