import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { MinimalSeller } from 'types/Seller';

type Step = 'form' | 'processing';

const usePayModal = (parentPath: string) => {
  const history = useHistory();
  const seller = useCurrentSeller() as MinimalSeller;
  const companyName = seller.companyName || '';
  const complexAccount = useIsComplexAccount();
  const [step, setStep] = useState<Step>('form');
  const [florynPayPaymentId, setFlorynPayPaymentId] = useState<number | null>(
    null
  );

  const close = () => history.push(parentPath);

  const gotoProcessingStep = (inputFlorynPayPaymentId: number) => {
    setFlorynPayPaymentId(inputFlorynPayPaymentId);

    setStep('processing');
  };

  return {
    close,
    companyName,
    complexAccount,
    gotoProcessingStep,
    step,
    florynPayPaymentId,
  };
};

export default usePayModal;
