import { LOCATION_CHANGE } from 'redux-first-history';
import { ReduxState, VersionState } from 'types';
import { Middleware } from 'redux';
import { AUTHENTICATE_SUCCESS } from 'shared/redux/modules/session';

const gitRevisionHash = process.env.GIT_REVISION_HASH
  ? process.env.GIT_REVISION_HASH
  : window.APPLICATION_GLOBALS?.GIT_REVISION_HASH;

const hasBundleVersionChanged = (state: ReduxState) => {
  const currentBundleVersion = state.version.currentBundleVersion;

  return currentBundleVersion && currentBundleVersion !== gitRevisionHash;
};

export const checkBundleVersionMiddleware: Middleware<any, ReduxState> =
  (storeApi) => (next) => (action) => {
    switch ((action as any).type) {
      case AUTHENTICATE_SUCCESS: {
        if (
          hasBundleVersionChanged(storeApi.getState()) &&
          process.env.NODE_ENV === 'production'
        ) {
          window.location.assign((action as any).nextUrl || '/');
          // Don't skip next action.
        }
        break;
      }

      case LOCATION_CHANGE: {
        if (
          hasBundleVersionChanged(storeApi.getState()) &&
          process.env.NODE_ENV === 'production'
        ) {
          window.location.reload();
          return null; // Skip next action.
        }

        break;
      }
    }

    return next(action);
  };

export const checkBundleVersionReducer = (state: VersionState = {}, action) => {
  if (action.currentBundleVersion) {
    const { currentBundleVersion } = action;

    return {
      ...state,
      currentBundleVersion,
    };
  } else {
    return state;
  }
};
