import React from 'react';
import AmountField from 'charlie/components/AccountSummary/AmountField';
import Settlement from 'charlie/components/AccountSummary/Settlement';

interface Props {
  currentBalance: number;
  settlement: number;
}

const BottomLeft = ({ currentBalance, settlement }: Props) => {
  if (settlement !== 0) {
    return <Settlement amount={settlement} />;
  } else {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2">
        <AmountField amount={currentBalance} translationKey="current_balance" />
      </div>
    );
  }
};

export default BottomLeft;
