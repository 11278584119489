import { useEffect, useState } from 'react';
import client, { Result } from 'shared/utils/client';
import { sleep } from 'shared/utils/asyncUtils';

interface Params<T> {
  path: string;
  timeMs: number;
  callback: (response: T) => boolean;
}

/* Poll a url with an interval.

 * path: url to poll
 * timeMs: wait time in milliseconds; the wait time doesn't include the request time.
 * callback: called when there is a response
   * return true to continue polling
   * return false to stop polling
 */
function usePoll<Payload>({ path, timeMs, callback }: Params<Payload>) {
  const [continuePolling, setContinuePolling] = useState(true);
  const [pollIndex, setPollIndex] = useState(1);

  useEffect(() => {
    let ignore = false;

    const poll = async () => {
      if (!continuePolling || ignore) {
        return;
      }

      try {
        const response = (await client('GET', path, {})) as Result<Payload>;

        if (callback(response.payload as Payload)) {
          await sleep(timeMs);
          setPollIndex((value) => value + 1);
        } else {
          ignore = true;
          setContinuePolling(false);
        }
      } catch {
        setContinuePolling(false);
        ignore = true;
      }
    };

    if (!ignore) {
      poll();
    }

    return () => {
      ignore = true;
    };
  }, [pollIndex]);
}

export default usePoll;
