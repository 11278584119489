import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { usesClientBankAccount } from 'charlie/model';
import { DescriptionList, Description } from './DescriptionList';
import LoanDescription from './LoanDescription';
import CreditProductDescription from './CreditProductDescription';
import useExtraPayOffModal from './useExtraPayOffModal';

const ns = 'charlie.frontpage.extra_pay_off_modal';

interface Props {
  parentPath: string;
}

const ExtraPayOffModal = ({ parentPath }: Props) => {
  const {
    currentBalance,
    florynIban,
    transferReference,
    maxRemainingExtraRepaymentAmount,
    onHide,
    seller,
  } = useExtraPayOffModal(parentPath);

  const iban =
    formatIban(
      usesClientBankAccount(seller) ? seller.clientBankAccount : florynIban
    ) ?? '';

  const toTheAttentionOf = usesClientBankAccount(seller)
    ? seller.companyName
    : I18n.nt(ns, 'foundation_floryn_payments');

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="tw-mb-8">{I18n.nt(ns, 'intro')}</p>
        <div className="well tw-mb-8">
          <DescriptionList>
            <Description term={I18n.nt(ns, 'iban')} details={iban} />
            <Description
              term={I18n.nt(ns, 'to_the_attention_of')}
              details={toTheAttentionOf}
            />
            <Description
              term={I18n.nt(ns, 'description')}
              details={I18n.nt(ns, 'reference', {
                reference: transferReference,
              })}
            />
          </DescriptionList>
        </div>
        {seller.hasLoan ? (
          <LoanDescription
            currentBalance={currentBalance}
            maxRemainingExtraRepaymentAmount={maxRemainingExtraRepaymentAmount}
          />
        ) : (
          <CreditProductDescription
            currentBalance={currentBalance}
            hasDebtCollection={!usesClientBankAccount(seller)}
          />
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ExtraPayOffModal;
