import React from 'react';

interface Props {
  onClick: () => void;
}

const Hamburger = ({ onClick }: Props) => (
  <a
    id="hamburger"
    className="tw-flex tw-h-6 tw-w-6 tw-flex-col tw-items-center tw-justify-around"
    href="#"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    <span className="tw-h-0.5 tw-w-6 tw-rounded-lg tw-bg-white" />
    <span className="tw-h-0.5 tw-w-6 tw-rounded-lg tw-bg-white" />
    <span className="tw-h-0.5 tw-w-6 tw-rounded-lg tw-bg-white" />
  </a>
);

export default Hamburger;
