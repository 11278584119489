import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AlertWithLink } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import useLiableWarning from './useLiableWarning';

const determineActionUrl = (
  basePath: string,
  liable: number,
  idealEnabled: boolean
) => {
  const idealAvailable = Boolean(
    idealEnabled && liable > 0 && liable <= 50_000
  );

  return idealAvailable
    ? `${basePath}/achterstand-terugbetalen`
    : `${basePath}/extra-aflossen`;
};

const ns = 'charlie.frontpage.warnings';

const LiableWarning = () => {
  const {
    debtCollectionAmount,
    liable,
    messageKey,
    messageLevel,
    idealEnabled,
  } = useLiableWarning();
  const match = useRouteMatch();
  const actionUrl = determineActionUrl(match.path, liable, idealEnabled);

  if (!messageLevel) {
    return null;
  }

  const message = I18n.nt(ns, messageKey, {
    doubleDebtCollectionAmount: toEuro(debtCollectionAmount * 2),
    debtCollectionAmountTimes4: toEuro(debtCollectionAmount * 4),
    liableAmount: toEuro(liable),
  });

  return (
    <AlertWithLink
      actionText={I18n.nt(ns, 'manual_repayment')}
      actionUrl={actionUrl}
      type={messageLevel}
    >
      {message}
    </AlertWithLink>
  );
};

export default LiableWarning;
