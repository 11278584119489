import { useSelector } from 'react-redux';
import useLiable from 'charlie/hooks/useLiable';
import { AlertType } from 'shared/components/tailwind/Alert';
import { ReduxState } from 'types';

const useLiableWarning = () => {
  const { debtCollectionAmount, liableAmount, reversalCount } = useLiable();

  const getMessageLevelAndKey = (): [AlertType | null, string] => {
    if (liableAmount <= 0) {
      return [null, ''];
    }

    const alertType = reversalCount <= 2.0 ? 'warning' : 'danger';
    return [alertType, 'liable_message'];
  };

  const [messageLevel, messageKey] = getMessageLevelAndKey();
  const idealEnabled = useSelector((state: ReduxState) =>
    Boolean(state.charlie.dashboard?.payload?.idealEnabled)
  );

  return {
    debtCollectionAmount,
    liable: liableAmount,
    idealEnabled,
    messageKey,
    messageLevel,
  };
};

export default useLiableWarning;
