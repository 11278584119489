import { Attachment } from '../types';
import * as Bank from '../Bank';
import { AisAccount, MissingPeriod } from 'types/Review';
import * as DeliveryType from './DeliveryType';
import Consent from 'types/Consent';
import { groupBy, uniq, values } from 'lodash';
import * as Status from './Status';
import * as AvailabilityPeriod from 'models/AvailabilityPeriod';
import * as AvailabilityPeriods from 'models/AvailabilityPeriods';
import * as Period from 'models/Period';

export type Iban = string;

export type IbanTask = {
  iban?: Iban;
  status: Status.Status;
  bank: Bank.Any;
  delivery: DeliveryType.DeliveryType;
  message?: string;
  attachments: Attachment[];
  isRequired?: boolean;
  missingPeriods: MissingPeriod[];
  consent?: Consent;
};

/*
 * Given multiple IbanTasks for the same IBAN, combine them into
 * a single new IBAN task.
 */
export function combine(a: IbanTask, b: IbanTask): IbanTask {
  return {
    ...a,
    delivery: DeliveryType.combine(a.delivery, b.delivery),
    attachments: uniq([...a.attachments, ...b.attachments]),
    missingPeriods: uniq([...a.missingPeriods, ...b.missingPeriods]),
    consent: a.consent ?? b.consent,
    status: Status.combine(a.status, b.status),
  };
}

/*
 * Given a list of IbanTasks, combine all the tasks for the same IBANs
 * together using `combine`.
 */
export function combineMultiple(ibanTasks: IbanTask[]): IbanTask[] {
  return values(groupBy(ibanTasks, 'iban')).map((t) => t.reduce(combine));
}

export function mergeConsent(
  ibanTask: IbanTask,
  consent: Consent,
  reviewEndDate: Date,
  nextReviewEndDate: Date
): IbanTask {
  if (ibanTask.iban !== consent.iban) {
    return ibanTask;
  }

  const newBank =
    ibanTask.bank === 'unknown' ? Bank.parse(consent.bank) : ibanTask.bank;
  let newStatus: Status.Status;
  let newMissingPeriods: MissingPeriod[] = [];
  if (!consent.expiresOn) {
    newStatus = 'pending';
  } else {
    const expiresOn = new Date(consent.expiresOn);
    if (expiresOn < reviewEndDate) {
      newStatus = 'warnings';
      newMissingPeriods = [
        {
          iban: consent.iban,
          startDate: consent.expiresOn,
          endDate: reviewEndDate.toString(),
        },
      ];
    } else if (expiresOn < nextReviewEndDate) {
      newStatus = 'expiresSoon';
      newMissingPeriods = [
        {
          iban: consent.iban,
          startDate: consent.expiresOn,
          endDate: nextReviewEndDate.toString(),
        },
      ];
    } else {
      newStatus = 'completed';
    }
  }

  return {
    ...ibanTask,
    status: newStatus,
    consent: consent,
    bank: newBank,
    missingPeriods: [...ibanTask.missingPeriods, ...newMissingPeriods],
    delivery: DeliveryType.combine(ibanTask.delivery, 'psd2'),
  };
}

export function addAttachment(
  ibanTask: IbanTask,
  attachment: Attachment
): IbanTask {
  if (ibanTask.iban === attachment.iban) {
    return { ...ibanTask, attachments: [...ibanTask.attachments, attachment] };
  } else {
    return ibanTask;
  }
}

/*
 * Copies the IBAN task to a new object and updates the properties
 * to the new values.
 */
export function update(
  ibanTask: IbanTask,
  newProps: Partial<IbanTask>
): IbanTask {
  return { ...ibanTask, ...newProps };
}

/*
 * Returns `true` if the IBAN task can be submitted,
 * and `false` otherwise.
 */
export function isSubmittable(ibanTask: IbanTask): boolean {
  return ibanTask.status === 'completed' || ibanTask.status === 'expiresSoon';
}

/*
 * Take an uploaded attachment and turn it into an IbanTask that can be
 * displayed in the review wizard.
 */
export function fromAttachment(attachment: Attachment): IbanTask {
  const bank = Bank.parse(attachment.bank);
  let status: Status.Status;

  if (bank === 'unknown') {
    status = 'unrecognised';
  } else {
    status = 'completed';
  }

  return {
    iban: attachment.iban || undefined,
    status: status,
    bank: bank,
    delivery: 'pdf',
    message: attachment.diagnostics?.date ?? attachment.fileName,
    attachments: [attachment],
    missingPeriods: [],
  };
}

export function fromConsent(consent: Consent): IbanTask {
  return {
    iban: consent.iban,
    status: 'completed',
    bank: Bank.parse(consent.bank),
    delivery: 'psd2',
    consent: consent,
    missingPeriods: [],
    attachments: [],
  };
}

export function messageFromAvailabilityPeriods(
  availabilityPeriods: AvailabilityPeriod.AvailabilityPeriod[]
): string {
  const latestPeriod = AvailabilityPeriods.latestPeriod(availabilityPeriods);
  if (latestPeriod) {
    return Period.format(latestPeriod);
  } else {
    return '';
  }
}

export function fromAisAccount(account: AisAccount): IbanTask {
  const message = messageFromAvailabilityPeriods(account.periods);
  return {
    iban: account.iban,
    status: 'completed',
    bank: Bank.parse(account.bank),
    delivery: DeliveryType.fromAvailabilityPeriods(account.periods),
    message: message,
    missingPeriods: [],
    attachments: [],
  };
}

export function pending(iban: string): IbanTask {
  return {
    iban: iban,
    status: 'pending' as Status.Status,
    bank: Bank.fromIban(iban),
    delivery: 'unknown' as DeliveryType.DeliveryType,
    message: '',
    missingPeriods: [],
    attachments: [],
  };
}

export function addMissingPeriod(
  ibanTask: IbanTask,
  missingPeriod: MissingPeriod
): IbanTask {
  if (missingPeriod.iban === ibanTask.iban) {
    return {
      ...ibanTask,
      status: 'warnings',
      missingPeriods: [...(ibanTask.missingPeriods ?? []), missingPeriod],
    };
  } else {
    return ibanTask;
  }
}

export function isBothPdfAndPsd2({ delivery }: IbanTask): boolean {
  return DeliveryType.isBoth(delivery);
}

export function isPdf({ delivery }: IbanTask): boolean {
  return DeliveryType.isPdf(delivery);
}

export function isPsd2({ delivery }: IbanTask): boolean {
  return DeliveryType.isPsd2(delivery);
}

export function hasWarnings(ibanTask: IbanTask): boolean {
  return ibanTask.attachments.some((a) => a.diagnostics?.type === 'warning');
}

export function hasAttachments(ibanTask: IbanTask): boolean {
  return ibanTask.attachments.length > 0;
}

export function isUnrecognised(ibanTask: IbanTask): boolean {
  return (
    ibanTask.status === 'unrecognised' ||
    ibanTask.attachments.some((a) => a.diagnostics?.type === 'error')
  );
}
