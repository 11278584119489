import Attachment from './Attachment';
import CreditDecision from './CreditDecision';

export enum ProductVersion {
  ALFA = 'alfa',
  CHARLIE = 'charlie',
  DELTA = 'delta',
}

export enum Status {
  DRAFT = 'draft',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  LOST = 'lost',

  CANCELLED = 'cancelled',
  INACTIVE = 'inactive',
}

export type VerificationStatus =
  | 'new'
  | 'pending_cent_verification'
  | 'verified';

// Went for a union type instead of an enum here, because babel seems to mess
// up when you use a key from an exported enum type
export type SignupType =
  | 'credit_request'
  | 'dashboard_request'
  | 'transferred_dashboard_request';

export type QuestionStatus =
  | 'all_questions_answered'
  | 'no_questions'
  | 'unanswered_questions';

export type ExtendedProductVersion = 'alfa' | 'charlie' | 'loan' | 'delta';

export interface Questions {
  inquiry: {
    status: QuestionStatus;
    unansweredCount: number;
  };
  requirement: {
    status: QuestionStatus;
    unansweredCount: number;
  };
}

export interface BaseSeller {
  aBTests: Record<string, boolean>;
  aisConnected?: boolean;
  canViewFinancialDashboard: boolean;
  clientBankAccount?: string;
  company: number;
  companyName: string;
  creditLimit?: string;
  expectedFinancingAmount: string | null;
  fullName: string;
  hasLoan: boolean;
  id: number;
  eligibleForDirectIdentification: boolean;
  inGracePeriod: boolean;
  instantPaymentAvailableAmount: number;
  instantPaymentEnabled: boolean;
  maySubmitUnfinancedInvoices: boolean;
  mt940Export: boolean;
  numberOfWeeksToPayOffDebt?: number;
  phone: string;
  productVersion: ProductVersion;
  sendInvoices: boolean;
  signupReviewId?: number;
  signupType: SignupType;
  status: Status;
  upcomingReviewDate?: string;
  useGAccount: boolean;
  showProposalSummary: boolean;
  showAutomatedOffer: boolean;
  withdrawalWillRequireApproval: boolean;
}

export interface MinimalSeller extends BaseSeller {
  entityKind: 'MinimalSeller';
}

export interface Seller extends BaseSeller {
  availableBalance: string;
  bankAccountVerificationStatus: VerificationStatus;
  bankStatementAttachments: number[];
  baseInterestRate?: string;
  charlieCollectionMethod: string;
  closingCommissionAmount: string;
  closingCommissionRate: string;
  contract?: number;
  debtCollectionMandateState: string;
  debtCollectionRate: string;
  debtCollectionSignatureDate?: string;
  email: string;
  entityKind: 'Seller';
  expectedRevenue: string | null;
  financeAllInvoices: string | null;
  florynInvoiceCcEmail?: string;
  hasEuribor?: boolean;
  iban?: string;
  identificationState?: string;
  identifyingPeopleStatus: string;
  interestRate?: string;
  invoiceIngressEmail?: string;
  lastTransitionDate?: string;
  minimumWithdrawalAmount: string;
  propertyAddress: string | null;
  questions: Questions;
  readyForIdentification: boolean;
  referrer: string;
  serviceFeeRate: string;
  signedContractFiles: number[];
  signupBy: 'floryn' | 'partner' | 'seller';
  smallLogoUrl: string;
  swanOnboardingState?: string;
  ubosConfirmedBy?: string;
  ubosFinishedAndConfirmed: boolean;
  valuationReports: Attachment[];
  verifiedIban?: string;
  withdrawalFeeRate: string;
  creditDecision: CreditDecision;
}

export interface AlfaSeller extends Seller {
  advanceRate: string;
  exampleInvoices: Attachment[];
  fixedRate: string;
  variableRate: string;
}
