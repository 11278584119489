import React from 'react';
import { useHistory } from 'react-router';
import { Modal } from 'shared/bootstrap';
import { subDays } from 'date-fns';

import * as I18n from 'shared/utils/I18n';
import { Button } from 'shared/bootstrap';
import Select from 'hookForm/Select';
import DateRangePickerInput from 'hookForm/DateRangePickerInput';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import ServerError from 'shared/components/ServerError';
import { trackEvent } from 'shared/utils/tracker';
import useExportModal from './useExportModal';

const ns = 'charlie.frontpage.export_modal';

interface Props {
  parentPath: string;
}

export default ({ parentPath }: Props) => {
  const {
    canExportMt940,
    control,
    exportInfoStatus,
    isValid,
    submitForm,
    isSubmitting,
    transactionsAvailableDate,
  } = useExportModal();
  const { push } = useHistory();

  const submit = () => {
    submitForm();
    push(parentPath);
    trackEvent('exported-transactions');
  };

  const options: [string, string][] = [['csv', I18n.nt(ns, 'csv')]];
  if (canExportMt940) options.push(['mt940', I18n.nt(ns, 'mt940')]);

  return (
    <Modal show onHide={() => push(parentPath)} className="c-export-modal">
      <form onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {exportInfoStatus === 'pending' && <LoadingAnimation />}
          {exportInfoStatus === 'failure' && <ServerError />}
          {exportInfoStatus === 'success' && (
            <>
              <p className="intro">{I18n.nt(ns, 'intro')}</p>
              {transactionsAvailableDate && (
                <p>
                  {I18n.nt(ns, 'transactions_available', {
                    date: transactionsAvailableDate,
                  })}
                </p>
              )}
              <DateRangePickerInput
                name="period"
                control={control}
                maxDate={subDays(new Date(), 1)}
              />
              <div className="form-group">
                <Select
                  name="format"
                  control={control}
                  label={I18n.nt(ns, 'format')}
                  options={options}
                />
              </div>
              <div className="well">{I18n.nt(ns, 'summary_html')}</div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {exportInfoStatus === 'success' && (
            <Button
              type="submit"
              className="btn-primary"
              disabled={!isValid || isSubmitting}
            >
              {I18n.nt(ns, 'title')}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};
