import { useState } from 'react';
import client from 'shared/utils/client';
import { trackFormSubmit } from 'shared/utils/tracker';
import useLoginSuccess from './useLoginSuccess';

interface LoginParams {
  email: string;
  password: string;
}

export type Status =
  | ''
  | 'account_locked'
  | 'authenticated'
  | 'authenticating'
  | 'invalid_login'
  | 'otp_login'
  | 'server_error';

interface Otp {
  intermediaryToken: string;
  lastThreeNumbers: string;
}

const useLogin = () => {
  const handleSuccessfulLogin = useLoginSuccess();

  const [status, setStatus] = useState<Status>('');
  const [otp, setOtp] = useState<Otp | null>(null);

  const login = async (values: LoginParams) => {
    setStatus('authenticating');

    const response = await client(
      'POST',
      '/api/session_v2/authenticate_user',
      values,
      { raiseError: false }
    );

    if (response.error) {
      switch (response.payload.name) {
        case 'account_locked':
          setStatus('account_locked');
          break;
        case 'invalid_login':
          setStatus('invalid_login');
          break;
        default:
          setStatus('server_error');
          break;
      }

      trackFormSubmit('login', false);
    } else if (response.payload.otpRequired) {
      setStatus('otp_login');
      setOtp({
        intermediaryToken: response.payload.otpIntermediaryToken,
        lastThreeNumbers: response.payload.lastThreeNumbers,
      });
    } else {
      handleSuccessfulLogin(response.payload);
    }
  };

  const switchToNormalLogin = () => {
    setStatus('');
  };

  return { login, otp, status, switchToNormalLogin };
};

export default useLogin;
