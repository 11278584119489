import React from 'react';
import * as I18n from 'shared/utils/I18n';
import Well from 'shared/components/tailwind/Well';
import { dateFormat, toEuro, toPercentage } from 'shared/utils/Helpers';

const ns = 'charlie.frontpage.floryn_pay_add_payment.summary';
const t = (key: string, options?) => I18n.nt(ns, key, options);

interface Summary {
  amount: number;
  debtCollectionDate: Date | undefined;
  creditorIban: string;
  creditorName: string;
  newCurrentBalance: number;
  newDebtCollectionAmount: number;
  withdrawalFee: number;
  withdrawalFeeRate: number;
}

interface Props {
  summary: Summary;
}

const PaySummary = ({ summary }: Props) => {
  if (!summary.amount) {
    return null;
  }

  return (
    <Well className="PaySummary tw-mb-8 tw-mt-6">
      <h5>{t('title')}</h5>
      <ul>
        {summary.creditorName ? (
          <li>
            {t('amount_to_transfer_to_name_html', {
              amount: toEuro(summary.amount),
              name: summary.creditorName,
            })}
          </li>
        ) : (
          <li>
            {t('amount_to_transfer_html', { amount: toEuro(summary.amount) })}
          </li>
        )}
        {summary.withdrawalFeeRate > 0 && (
          <li>
            {t('withdrawal_fee_html', {
              withdrawalFee: toEuro(summary.withdrawalFee),
              withdrawalFeeRate: toPercentage(summary.withdrawalFeeRate),
              amount: toEuro(summary.amount),
            })}
          </li>
        )}
        <li>
          {t('new_current_balance_html', {
            amount: toEuro(summary.newCurrentBalance),
          })}
        </li>
        <li>
          {t('new_debt_collection_amount_html', {
            amount: toEuro(summary.newDebtCollectionAmount),
          })}
        </li>
        <li>
          {t('new_debt_collection_date_html', {
            date: dateFormat(summary.debtCollectionDate),
          })}
        </li>
      </ul>
    </Well>
  );
};

export default PaySummary;
