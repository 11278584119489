import React from 'react';
import { Controller } from 'react-hook-form';
import TextInput from './TextInput';

const formatIban = (value) => {
  const cleaned = value.replace(/[^\da-zA-Z]/g, '').toUpperCase();
  return cleaned.replace(/(.{4})/g, '$1 ').trim();
};

const IbanInput = ({ control, label, name }) => {
  const handleIbanChange = (event, onChange) => {
    const { target } = event;
    const cursorPosition = target.selectionStart;
    const rawValue = target.value;

    const rawValueWithoutSpaces = rawValue.replace(/ /g, '');

    const formattedValue = formatIban(rawValueWithoutSpaces);

    const CharBeforeCursor = rawValue
      .slice(0, cursorPosition)
      .replace(/ /g, '');

    const formattedBeforeCursor = formatIban(CharBeforeCursor);

    const newCursorPosition = formattedBeforeCursor.length;

    onChange(formattedValue);

    setTimeout(() => {
      target.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextInput
          label={label}
          name={name}
          control={control}
          maxLength={22}
          placeholder={'NL12 FLRN 0000 1234 56'}
          value={value || ''}
          onChange={(e) => handleIbanChange(e, onChange)}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default IbanInput;
