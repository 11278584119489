import React from 'react';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.repayment_modal';

interface Props {
  disabled: boolean;
  initiatePayment: () => Promise<void>;
}

const IdealButton = ({ disabled, initiatePayment }: Props) => (
  <div className="tw-mb-6 tw-flex tw-justify-end">
    <Button
      disabled={disabled}
      onClick={initiatePayment}
      className="btn-primary-inverted tw-flex tw-items-center tw-gap-3 tw-rounded-full tw-py-1 tw-pl-4 tw-pr-2"
    >
      {I18n.nt(ns, 'pay_with_ideal')}
      <img
        className="tw-w-10"
        aria-label="ideal-logo"
        src="/images/ideal-logo-1024.png"
      />
    </Button>
  </div>
);

export default IdealButton;
