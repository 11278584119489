import React from 'react';
import { Route } from 'react-router-dom';

import Action, { ActionButton } from '../Action';
import { OnboardingActionStatus } from 'types';
import { nt } from '../utils';
import MandateStart from 'signup/components/seller/MandateStart';

const t = nt('signup.onboarding.mandate');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

const MandateAction = ({ basePath, status }: Props) => {
  const mandatePath = `${basePath}/machtiging`;
  const Button = () => (
    <ActionButton to={mandatePath}>{t('button')}</ActionButton>
  );

  const MandateModal = ({ history }) => {
    const onClose = () => history.push(basePath);

    return <MandateStart onClose={onClose} />;
  };

  const PendingDescription = (
    <div className="tw-mt-2">
      <div>{t('description.pending.intro')}</div>
      <ol className="tw-ml-6 tw-mt-4 tw-list-inside tw-list-decimal">
        <li>{t('description.pending.step_1')}</li>
        <li>{t('description.pending.step_2_html')}</li>
        <li>{t('description.pending.step_3_html')}</li>
        <li>{t('description.pending.step_4')}</li>
      </ol>
    </div>
  );

  return (
    <>
      <Action
        button={status === 'Todo' ? Button : null}
        description={
          status === 'Pending' ? PendingDescription : t('description.default')
        }
        path={mandatePath}
        status={status}
        title={t('title')}
      />
      <Route path={mandatePath} render={MandateModal} />
    </>
  );
};

export default MandateAction;
