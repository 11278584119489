import React, { useEffect } from 'react';

import { trackEvent } from 'shared/utils/tracker';
import ContentWithSidebar from 'shared/components/tailwind/ContentWithSidebar';
import ContainerWithoutPadding from 'shared/components/tailwind/ContainerWithoutPadding';
import { Contract, Seller } from 'types';
import {
  bankAccountVerificationStatus,
  contractStatus,
  guaranteeStatus,
  mandateStatus,
  uboStatus,
  verifyIdentityStatus,
  withdrawalStatus,
} from 'models/Onboarding';
import { isAlfa, isCharlie } from 'models/Seller';
import { nt } from './utils';
import SellerActions from './SellerActions';
import ContractInfo from './ContractInfo';
import Guarantee from './Guarantee';
import Mandate from './Mandate';
import QuestionsAction from './Questions/QuestionsAction';
import UltimateBeneficialOwners from './UltimateBeneficialOwners';
import VerifyAccount from './VerifyAccount';
import VerifyIdentity from './VerifyIdentity';
import Withdrawal from './Withdrawal';
import ProposalSummary from './ProductInformation/ProposalSummary';
import OnboardingSidebar from './OnboardingSidebar';
import FinancialDashboardStatus from './FinancialDashboardStatus';
import GoogleReviews from './GoogleReviews';
import ProposalLink from './ProductInformation/ProposalLink';
import ProductVideo from './ProductVideo';

const t = nt('signup.onboarding.with_contract');

interface Props {
  basePath: string;
  contract: Contract;
  seller: Seller;
}

const WithContract = ({ basePath, contract, seller }: Props) => {
  useEffect(() => {
    trackEvent('contracting_visit');
  }, []);

  return (
    <>
      <ContainerWithoutPadding>
        <div className="tw-mb-6 tw-bg-[#e0f0da] tw-px-4 tw-py-4 sm:tw-px-8 md:tw--mx-8 md:tw-mb-10 md:tw-mt-7 md:tw-rounded-lg md:tw-py-10">
          <div className="tw-mb-2 tw-font-heading tw-text-lg tw-font-bold md:tw-text-bootstrap-h3">
            {t('banner.title')}
          </div>
          <div className="tw-text-lg md:tw-text-2xl">{t('banner.message')}</div>
        </div>
      </ContainerWithoutPadding>
      <ContentWithSidebar
        aboveMainChildren={
          <h3 className="tw-text-lg md:tw-mb-5 md:tw-text-bootstrap-h3">
            {t('title')}
          </h3>
        }
        includeTopMargin={false}
        sidebarChildren={<OnboardingSidebar />}
        sidebarClassName=""
      >
        <div className="Profile">
          <div className="tw-flex tw-flex-col tw-gap-y-5 md:tw-mr-16 md:tw-gap-y-8">
            <ProposalSummary />
            <ProposalLink basePath={basePath} seller={seller} />
            <div>
              {t('description')} {!isAlfa(seller) && t('transfer_promise')}
            </div>
            <SellerActions>
              {isCharlie(seller) && !seller.hasLoan && (
                <Withdrawal
                  basePath={basePath}
                  seller={seller}
                  status={withdrawalStatus(seller)}
                />
              )}
              {seller.questions.inquiry.status !== 'no_questions' && (
                <QuestionsAction
                  basePath={basePath}
                  kind="inquiry"
                  status={
                    seller.questions.inquiry.status !== 'all_questions_answered'
                      ? 'Todo'
                      : 'Done'
                  }
                />
              )}
              {seller.questions.requirement.status !== 'no_questions' && (
                <QuestionsAction
                  basePath={basePath}
                  kind="requirement"
                  status={
                    seller.questions.requirement.status !==
                    'all_questions_answered'
                      ? 'Todo'
                      : 'Done'
                  }
                />
              )}
              {seller.eligibleForDirectIdentification ? (
                <VerifyIdentity status={verifyIdentityStatus(seller)} />
              ) : (
                <UltimateBeneficialOwners status={uboStatus(seller)} />
              )}
              {!isAlfa(seller) && (
                <Mandate basePath={basePath} status={mandateStatus(seller)} />
              )}
              {!isAlfa(seller) && (
                <VerifyAccount
                  basePath={basePath}
                  seller={seller}
                  status={bankAccountVerificationStatus(seller)}
                />
              )}
              <ContractInfo
                contract={contract}
                seller={seller}
                status={contractStatus(contract)}
              />
              <Guarantee
                basePath={basePath}
                contract={contract}
                status={guaranteeStatus(contract)}
              />
            </SellerActions>
            <ProductVideo seller={seller} />
            <div className="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2">
              <FinancialDashboardStatus />
              <GoogleReviews />
            </div>
          </div>
        </div>
      </ContentWithSidebar>
    </>
  );
};

export default WithContract;
