import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import { usesClientBankAccount } from 'charlie/model';
import { isCancelled } from 'models/Seller';
import { ReduxState } from 'types';
import useAutomaticWithdrawal from './useAutomaticWithdrawal';
import useCanWithdraw from './useCanWithdraw';

const useCharlieActions = () => {
  const canWithdraw = useCanWithdraw();
  const idealEnabled = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.idealEnabled ?? false
  );
  const liableAmount = useSelector((state: ReduxState) =>
    parseFloat(state.charlie.dashboard?.payload?.liable ?? '0')
  );

  const florynPayAllowed = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.florynPayAllowed ?? false
  );

  const { automaticWithdrawalVisible } = useAutomaticWithdrawal();

  const seller = useCurrentSeller();
  const history = useHistory();
  const match = useRouteMatch();

  const increaseLimitButtonVisible = !isCancelled(seller);
  const transactionsButtonVisible = usesClientBankAccount(seller);
  const payButtonVisible =
    Boolean(seller.aBTests.florynPay) && florynPayAllowed;

  return {
    automaticWithdrawalVisible,
    canWithdraw,
    history,
    idealEnabled,
    increaseLimitButtonVisible,
    liableAmount,
    match,
    payButtonVisible,
    seller,
    transactionsButtonVisible,
  };
};

export default useCharlieActions;
