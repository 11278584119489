import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import { currentSellerSelector } from 'shared/selectors';
import DeltaExtraPayOffModal from '../ExtraPayOffModal/DeltaExtraPayOffModal';
import SearchForm from '../SearchForm';
import { MinimalSeller, ReduxState } from 'types';
import DeltaRepaymentModal from '../RepaymentModal/DeltaRepaymentModal';

const ns = 'charlie.frontpage.actions';

const DeltaActions = () => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const match = useRouteMatch();

  const liableAmount = useSelector((state: ReduxState) =>
    parseFloat(state.charlie.dashboard?.payload?.liable ?? '0')
  );

  return (
    <div className="c-actions">
      <div className="c-actions-links">
        <Link to={`${match.path}/extra-aflossen`} className="btn btn-tertiary">
          {I18n.nt(ns, 'extra_pay_off')}
        </Link>
        <Route
          path={`${match.path}/extra-aflossen`}
          render={(props) => (
            <DeltaExtraPayOffModal parentPath={match.path} {...props} />
          )}
        />
        <Route path={`${match.path}/achterstand-terugbetalen`}>
          <DeltaRepaymentModal
            parentPath={match.path}
            amountInEuros={liableAmount}
          />
        </Route>
      </div>
      <SearchForm sellerId={seller.id} />
    </div>
  );
};

export default DeltaActions;
