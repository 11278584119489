import { useCurrentSeller } from 'namespaces/shared/hooks';
import { MinimalSeller, ReduxState } from 'types';
import { setAutomaticWithdrawal } from 'charlie/modules/dashboardSlice';
import useAppDispatch from 'namespaces/shared/hooks/useAppDispatch';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import {
  validateAll,
  validatePresence,
  validateGreaterThanOrEqualTo,
} from 'shared/utils/validation';
import * as I18n from 'shared/utils/I18n';
import { toFloat } from 'namespaces/shared/utils/Helpers';

const ns = 'charlie.frontpage.automatic_withdraw_modal';

interface FormValues {
  amount: number;
}

const validateLimit = (creditLimit: number) => (amount: number) =>
  amount <= creditLimit ? null : I18n.nt(ns, 'cannot_update_limit');

const validateAmount = (amount: number, creditLimit: number) =>
  validateAll(
    validatePresence,
    validateGreaterThanOrEqualTo(100),
    validateLimit(creditLimit)
  )(amount);

const useAutomaticWithdrawalModal = (parentPath: string) => {
  const history = useHistory();
  const onHide = () => {
    history.push(parentPath);
  };

  const creditLimitFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.creditLimit
  );

  const creditLimit = parseFloat(creditLimitFromState || '');

  const validate = ({ amount }: FormValues) => {
    const validationError = validateAmount(amount, creditLimit);

    return { amount: validationError };
  };

  // Fixed automatic withdrawal limit
  const fixedAutomaticWithdrawalLimitFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.fixedAutomaticWithdrawalLimit
  );
  const fixedAutomaticWithdrawalLimit =
    fixedAutomaticWithdrawalLimitFromState || '';

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: resolver(validate),
    defaultValues: {
      amount: toFloat(fixedAutomaticWithdrawalLimit) || null,
    },
  });

  const amount = useWatch({ control, name: 'amount' }) || 0.0;

  const automaticWithdrawalActivated = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.automaticWithdrawalActivated ?? false
  );

  const [error, setError] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const seller = useCurrentSeller() as MinimalSeller;

  const updateAutomaticWithdrawal = async () => {
    setError(false);
    const response = await dispatch(
      setAutomaticWithdrawal({
        sellerId: seller.id,
        amount,
      })
    );

    if (response.payload && setAutomaticWithdrawal.rejected.match(response)) {
      setError(true);
    } else {
      setTermsAccepted(false);
    }
  };

  return {
    amount,
    automaticWithdrawalActivated,
    control,
    error,
    errors,
    fixedAutomaticWithdrawalLimit,
    handleSubmit,
    isSubmitting,
    onHide,
    setTermsAccepted,
    termsAccepted,
    updateAutomaticWithdrawal,
  };
};

export default useAutomaticWithdrawalModal;
