import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import client from 'shared/utils/client';
import { getQueryParam } from 'shared/utils/Helpers';

type IdentificationState = 'error' | 'success';

const useFlorynPayIdentificationStatus = (parentPath: string) => {
  const history = useHistory();
  const [status, setStatus] = useState<IdentificationState>('success');

  const location = useLocation();
  const code = getQueryParam(location.search, 'code');
  const onboardingId = getQueryParam(location.search, 'state');
  const error = getQueryParam(location.search, 'error');

  const finalizeOnboarding = async () => {
    if (code && onboardingId) {
      await client(
        'POST',
        `/api/swan_onboarding/finalize`,
        {
          code,
          onboardingId,
        },
        { raiseError: false }
      );
    }
  };

  useEffect(() => {
    if (error) {
      setStatus('error');
    } else {
      finalizeOnboarding();
    }
  }, []);

  const close = () => history.push(parentPath);

  return {
    close,
    status,
  };
};

export default useFlorynPayIdentificationStatus;
