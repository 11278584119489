import { Attachment } from '../types';
import * as IbanTask from './IbanTask';
import { compact, sortBy, uniq } from 'lodash';
import Review, { MissingPeriod } from 'types/Review';
import Consent from 'types/Consent';

export type ReviewSheet = {
  ibanTasks: IbanTask.IbanTask[];
  newUploads: IbanTask.IbanTask[];
  submittable: boolean;
  overrideWarnings: boolean;
};

/*
 * Create an object containing the data that will be submitted.
 */
export function getSubmitData(reviewSheet: ReviewSheet): {
  bank_statement_attachments: number[];
} {
  return {
    bank_statement_attachments: compact(
      attachments(reviewSheet).map((a) => a.id)
    ),
  };
}

/*
 * Create a new, empty review sheet.
 */
export function empty(): ReviewSheet {
  return {
    ibanTasks: [],
    newUploads: [],
    submittable: false,
    overrideWarnings: false,
  };
}

/**
 * Check if a ReviewSheet has any IBAN tasks or not.
 */
export function isEmpty(reviewSheet: ReviewSheet): boolean {
  return (
    reviewSheet.ibanTasks.length === 0 && reviewSheet.newUploads.length === 0
  );
}

/**
 * Apply a function to all IBAN tasks in the review sheet in order, returning
 * a list of the results.
 */
export function map<T>(
  reviewSheet: ReviewSheet,
  fun: (ibanTask: IbanTask.IbanTask) => T
): T[] {
  return sortBy(
    [...reviewSheet.ibanTasks, ...reviewSheet.newUploads],
    ['bank', 'iban']
  ).map(fun);
}

/*
 * Map a function over all tasks in a review sheet, returning an updated review
 * sheet.
 */
function mapTasks(
  reviewSheet: ReviewSheet,
  fun: (ibanTask: IbanTask.IbanTask) => IbanTask.IbanTask
): ReviewSheet {
  return {
    ...reviewSheet,
    ibanTasks: reviewSheet.ibanTasks.map(fun),
    newUploads: reviewSheet.newUploads.map(fun),
  };
}

/**
 * Update a review sheet with a new attachment from the API. If we already have
 * a task for that IBAN, update that instead.
 */
export function addAttachment(
  reviewSheet: ReviewSheet,
  attachment: Attachment
): ReviewSheet {
  if (attachment.iban && hasIban(reviewSheet, attachment.iban)) {
    return mapTasks(reviewSheet, (t) => IbanTask.addAttachment(t, attachment));
  } else {
    const ibanTask = IbanTask.fromAttachment(attachment);

    return {
      ...reviewSheet,
      ibanTasks: [...reviewSheet.ibanTasks, ibanTask],
    };
  }
}

/*
 * Check if any of the IBAN tasks in the review sheet is for the given IBAN.
 */
function hasIban({ ibanTasks, newUploads }: ReviewSheet, iban: string) {
  return [...ibanTasks, ...newUploads].map((t) => t.iban).includes(iban);
}

export function addConsents(
  reviewSheet: ReviewSheet,
  consents: Consent[],
  startDate: Date,
  endDate: Date,
  nextEndDate: Date
): ReviewSheet {
  const newIbanTasks = consents
    .filter((c) => c.iban)
    .filter((c) => !hasIban(reviewSheet, c.iban))
    .filter((c) => c.expiresOn && new Date(c.expiresOn) > startDate)
    .map(IbanTask.fromConsent);

  return mapTasks(
    {
      ...reviewSheet,
      ibanTasks: [...reviewSheet.ibanTasks, ...newIbanTasks],
    },
    (ibanTask) =>
      consents.reduce(
        (t, consent) => IbanTask.mergeConsent(t, consent, endDate, nextEndDate),
        ibanTask
      )
  );
}

/**
 * Update a review sheet with a newly uploaded file that is not yet present in
 * the global Redux state.
 */
export function addUpload(
  reviewSheet: ReviewSheet,
  upload: Attachment
): ReviewSheet {
  const ibanTask = IbanTask.fromAttachment(upload);

  if (ibanTask.iban && hasIban(reviewSheet, ibanTask.iban)) {
    return mapTasks(reviewSheet, (t) => IbanTask.addAttachment(t, upload));
  } else {
    return {
      ...reviewSheet,
      newUploads: [...reviewSheet.newUploads, ibanTask],
    };
  }
}

/*
 * List all attachments from all tasks in a review sheet.
 */
export function attachments(reviewSheet: ReviewSheet): Attachment[] {
  return map(reviewSheet, (t) => t.attachments).flat(1);
}

/*
 * Helper function for applying `Array.prototype.some` to all `IbanTask`s in a
 * `ReviewSheet`.
 */
function someTasks(
  { ibanTasks, newUploads }: ReviewSheet,
  fun: (ibanTask: IbanTask.IbanTask) => boolean
): boolean {
  return [...ibanTasks, ...newUploads].some(fun);
}

function hasSomeAttachments(reviewSheet: ReviewSheet): boolean {
  return someTasks(reviewSheet, IbanTask.hasAttachments);
}

/*
 * Check whether we should/need to allow the user to override any warnings in
 * the review sheet using a checkbox. Overriding is useful for the user to
 * bypass our automated warnings; checking whether to allow overriding is useful
 * to only allow overriding when there actually are any errors.
 *
 * We allow overrides when there is at least one uploaded PDF. Who knows what
 * that one file might contain?
 */
export function isOverridable(reviewSheet: ReviewSheet): boolean {
  return hasSomeAttachments(reviewSheet);
}

/**
 * Set the warnings override. When we do a cat scan, we only look at the first
 * page of an uploaded file. Therefore we might incorrectly conclude that not
 * all necessary files have been provided.  This override allows the customer to
 * insist he or she has indeed uploaded all we need.
 */
export function setOverrideWarnings(
  reviewSheet: ReviewSheet,
  override: boolean
): ReviewSheet {
  return { ...reviewSheet, overrideWarnings: override };
}

/*
 * Returns `true` when...
 *
 * * All IBAN tasks are complete
 * * or if some are not complete, but there is at least one upload and the
 *   override options is checked.
 *
 * Otherwise, returns `false`.
 */
export function isSubmittable(reviewSheet: ReviewSheet): boolean {
  return (
    [...reviewSheet.ibanTasks, ...reviewSheet.newUploads].every((ibanTask) =>
      IbanTask.isSubmittable(ibanTask)
    ) ||
    (reviewSheet.overrideWarnings && attachments(reviewSheet).length > 0)
  );
}

export function fromReview(
  review: Review,
  bankStatementAttachments: Attachment[]
): ReviewSheet {
  const { requiredIbanTasks, otherIbanTasks } = getRequiredAndOtherIbanTasks(
    review,
    getFullyAvailableIbanTasks(review),
    getUploadedIbanTasks(bankStatementAttachments)
  );

  const unrecognised = bankStatementAttachments.filter(
    (b) => b.diagnostics?.type === 'error' || b.diagnostics?.type === 'warning'
  );

  return addConsents(
    addMissingPeriods(
      {
        ibanTasks: [...requiredIbanTasks, ...otherIbanTasks],
        newUploads: unrecognised.map(IbanTask.fromAttachment),
        submittable: false, // TODO
        overrideWarnings: false, // TODO
      },
      review.missingPeriods
    ),
    review.aisConsents,
    new Date(review.requestedStartDate),
    new Date(review.requestedEndDate),
    new Date(review.probableNextReviewEndDate)
  );
}

export function addMissingPeriods(
  reviewSheet: ReviewSheet,
  missingPeriods: MissingPeriod[]
): ReviewSheet {
  return mapTasks(reviewSheet, (ibanTask) =>
    missingPeriods.reduce(
      (t, missingPeriod) => IbanTask.addMissingPeriod(t, missingPeriod),
      ibanTask
    )
  );
}

function getFullyAvailableIbanTasks({
  fullyAvailableAccounts,
}: Review): IbanTask.IbanTask[] {
  const fullyAvailableIbanTasks = fullyAvailableAccounts.map((account) =>
    IbanTask.fromAisAccount(account)
  );

  return [...new Set(fullyAvailableIbanTasks)];
}

function getUploadedIbanTasks(attachments: Attachment[]): IbanTask.IbanTask[] {
  const uploadedIbanTasks = attachments.map((attachment) =>
    IbanTask.fromAttachment(attachment)
  );

  return [...new Set(uploadedIbanTasks)];
}

function getRequiredAndOtherIbanTasks(
  review: Review,
  fullyAvailableIbanTasks: IbanTask.IbanTask[],
  uploadedIbanTasks: IbanTask.IbanTask[]
): {
  otherIbanTasks: IbanTask.IbanTask[];
  requiredIbanTasks: IbanTask.IbanTask[];
} {
  const requiredIbans = uniq(review.requiredIbans);

  const otherIbanTasks = uniq(
    [...fullyAvailableIbanTasks, ...uploadedIbanTasks]
      .filter(
        (ibanTask) => ibanTask.iban && !requiredIbans.includes(ibanTask.iban)
      )
      .map((ibanTask) => IbanTask.update(ibanTask, { isRequired: false }))
  );

  const requiredIbanTasks = requiredIbans
    .slice()
    .sort()
    .flatMap((iban) => {
      let ibanTasks = [
        ...fullyAvailableIbanTasks.filter((t) => t.iban === iban),
        ...uploadedIbanTasks.filter((t) => t.iban === iban),
      ];
      if (ibanTasks.length === 0) {
        ibanTasks = [IbanTask.pending(iban)];
      }

      return ibanTasks.map((ibanTask) =>
        IbanTask.update(ibanTask, { isRequired: true })
      );
    });

  return {
    requiredIbanTasks: IbanTask.combineMultiple(requiredIbanTasks),
    otherIbanTasks: IbanTask.combineMultiple(otherIbanTasks),
  };
}

/*
 * List all files that contain an error in the review sheet.
 */
export function filesWithErrors(reviewSheet: ReviewSheet) {
  return attachments(reviewSheet).filter(
    (f) => f.diagnostics?.type === 'error'
  );
}

/*
 * List all files that contain a warning in the review sheet.
 */
export function filesWithWarnings(reviewSheet: ReviewSheet) {
  return attachments(reviewSheet).filter(
    (f) => f.diagnostics?.type === 'warning'
  );
}
