import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';

const ns = 'charlie.frontpage.warnings';

const LiableButton = () => {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <button
      onClick={() => history.push(`${match.path}/achterstand-terugbetalen`)}
      className="tw-font-bold tw-underline"
    >
      {I18n.nt(ns, 'manual_repayment')}
    </button>
  );
};

export default LiableButton;
