import React from 'react';
import SummaryLayout from 'charlie/components/AccountSummary/DashboardSummaryLayout';
import { calculatePercentage } from 'charlie/components/AccountSummary/utils';
import BottomLeft from './BottomLeft';
import BottomRight from './BottomRight';
import { useDashboardDetails } from './useDashboard';

const DesktopSummary = () => {
  const dashboardDetails = useDashboardDetails();

  if (!dashboardDetails) {
    return null;
  }

  const {
    availableBalance,
    creditLimit,
    currentBalance,
    nextRestriction,
    settlement,
  } = dashboardDetails;

  const chartPercentage = calculatePercentage(availableBalance, creditLimit);

  const topLeft = {
    amount: availableBalance,
    translationKey: 'available_balance',
  };

  return (
    <SummaryLayout
      chartPercentage={chartPercentage}
      renderBottomLeft={
        <BottomLeft currentBalance={currentBalance} settlement={settlement} />
      }
      renderBottomRight={
        <BottomRight
          amount={creditLimit}
          nextRestriction={nextRestriction}
          translationKey="credit_limit"
        />
      }
      topLeft={topLeft}
    />
  );
};

export default DesktopSummary;
