import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import classNames from 'classnames';
import { isBankStatementAttachment } from 'models/Attachment';
import { Attachment } from 'types';
import AttachmentsInput from './AttachmentsInput';
import { sanitize } from 'dompurify';

const renderDocumentMessage = (attachment: Attachment) => {
  if (
    !(
      attachment &&
      isBankStatementAttachment(attachment) &&
      attachment.diagnostics
    )
  ) {
    return null;
  }

  const display =
    attachment.diagnostics.type === 'warning' ||
    attachment.diagnostics.type === 'error';

  return (
    <>
      <span
        className={classNames(
          'document-input-list-item__message',
          display && 'document-input-list-item__message--warning'
        )}
        // see https://legacy.reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
        dangerouslySetInnerHTML={{
          __html: sanitize(attachment.diagnostics.message),
        }}
      ></span>

      <span className="document-input-list-item__message">
        {attachment.diagnostics.date}
      </span>
    </>
  );
};

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  model: string;
  modelId?: number;
  name: Path<TFieldValues>;
  readOnly?: boolean;
  setUploading?: (uploading: boolean) => void;
}

const BankstatementUploadInput = <TFieldValues extends FieldValues>({
  control,
  label,
  model,
  modelId,
  name,
  readOnly,
  setUploading,
}: Props<TFieldValues>) => (
  <AttachmentsInput
    control={control}
    allowMultipleFiles
    hideLabel
    label={label}
    model={model}
    modelId={modelId}
    name={name}
    readOnly={readOnly}
    renderDocumentMessage={renderDocumentMessage}
    setUploading={setUploading}
  />
);

export default BankstatementUploadInput;
