import React from 'react';
import { isArray } from 'lodash';

interface Props {
  title: string;
  value: string | string[];
}

const DataRow = ({ title, value }: Props) => (
  <div className="tw-flex tw-justify-between tw-py-3 tw-font-heading tw-text-sm">
    <div className="tw-items-start tw-font-medium tw-text-gray-800">
      {title}
    </div>
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-2 tw-text-gray-700">
      {isArray(value) ? value.map((v, i) => <div key={i}>{v}</div>) : value}
    </div>
  </div>
);

export default DataRow;
