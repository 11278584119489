import React from 'react';
import { createRoot } from 'react-dom/client';
import { combineReducers, configureStore, Tuple } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import AppRoot from './components/AppRoot';
import config from 'init/baseConfig';
import setupAll from 'init/setup';
import reducer from './reducer';
import commonMiddleware from 'shared/redux/commonMiddleware';

import 'html/index-app.html';
import './styles/index.scss';

setupAll(config);

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const store = configureStore({
  middleware: () => new Tuple(...commonMiddleware, routerMiddleware),
  reducer: {
    ...reducer,
    router: routerReducer,
  },
});

const history = createReduxHistory(store);

if (window.RAILS_ENV === 'development' && module.hot) {
  module.hot.accept('./reducer', () => {
    store.replaceReducer(
      combineReducers({
        ...reducer,
        router: routerReducer,
      })
    );
  });
}

createRoot(document.getElementById('app') as HTMLElement).render(
  <Provider store={store}>
    <Router history={history}>
      <AppRoot />
    </Router>
  </Provider>
);
